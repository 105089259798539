<template>
  <div class="iq-social-group">
    <div class="p--margin-0 margin-bottom-1 text-center">
      <p>alebo sa prihlásiť cez sociálne siete</p>
    </div>
    <ul class="iq-social-media">
      <li class="iq-social-media__item" v-for="(socialProvider, index) in socialProviders" :key="index">
        <a class="iq-social-media__link" :class="socialProvider.type" href="javascript:void(0)" @click="socialLogin(socialProvider.type)">
            <i :class="socialProvider.icon"></i>
            {{ socialProvider.text }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'SocialLoginForm',
  data: () => ({
    socialProviders: [
      {
        type: 'google',
        icon: 'ri-google-line margin-right-1',
        text: 'Prihlásiť sa cez Google'
      },
      {
        type: 'facebook',
        icon: 'ri-facebook-line margin-right-1',
        text: 'Prihlásiť sa cez Facebook'
      }
      /* {
        type: 'twitter',
        icon: 'ri-twitter-line'
      } */
    ]
  }),
  methods: {
    socialLogin (type) {
      let firebaseProvider = new firebase.auth.GoogleAuthProvider()
      if (type === 'facebook') {
        firebaseProvider = new firebase.auth.FacebookAuthProvider()
      } else if (type === 'twitter') {
        firebaseProvider = new firebase.auth.TwitterAuthProvider()
      }

      firebase.auth().signInWithPopup(firebaseProvider)
        .then(user => {
          let firebaseUser = firebase.auth().currentUser.providerData[0]
          this.$store.dispatch('Setting/authUserAction', {
            auth: true,
            authType: 'firebase',
            user: {
              id: firebaseUser.uid,
              name: firebaseUser.displayName,
              mobileNo: firebaseUser.phoneNumber,
              email: firebaseUser.email,
              profileImage: firebaseUser.photoURL
            }
          })
          localStorage.setItem('user', JSON.stringify(firebaseUser))
          this.$router.push({ name: 'mini.dashboard.home-1' })
        }).catch()
    }
  }
}
</script>

<style lang="scss" scoped>
.iq-social-group {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.iq-social-media {
	float: none;
}

.iq-social-media__item {
	margin-right: 0;
	width: 100%;
	float: none;
	margin-bottom: 0.5rem;
}

.iq-social-media__link {
	height: 50px;
	width: 100%;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding-left: 0;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	@include font-size(14px);
	font-weight: 700;
	text-align: center;
	border-radius: 25px;
	color: white !important;
	&.facebook {
		background-color: #4267b2;
		&:hover {
			background-color: darken(#4267b2, 10%);
		}
	}
	&.google {
		background-color: #ea4335;
		&:hover {
			background-color: darken(#ea4335, 10%);
		}
	}
	i {
		color: white;
	}
}
</style>
