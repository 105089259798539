/*

Uvod -> http://zdravotnici.sk
FAQ -> http://zdravotnici.sk/#/casto-kladene-otazky
Dokumenty -> http://zdravotnici.sk/#/dokumenty
Prečo sa registrovať -> http://zdravotnici.sk/#/preco-sa-registrovat
Vyhladať doktora -> http://zdravotnici.sk/#/vyhladat-doktora
Profil v1 -> http://zdravotnici.sk/#/profil-doktora/mudr-jana-kovacova
Profil v2 -> http://zdravotnici.sk/#/profil-doktora-v2/mudr-jana-kovacova

Prihlasenie -> http://zdravotnici.sk/#/auth/prihlasenie
Registrácia -> http://zdravotnici.sk/#/auth/registracia
Zabudnute heslo -> http://zdravotnici.sk/#/auth/reset-hesla

1x som v dashboarde:
Pridať profil -> http://zdravotnici.sk/#/dashboard/pridat-profil
Pridať zamestnanca & zamestnavatelov -> http://zdravotnici.sk/#/dashboard/pridat-zamestnavatela

Profil -> http://zdravotnici.sk/#/dashboard/profil
Upraviť profil -> http://zdravotnici.sk/#/dashboard/upravit-profil

Skladová karta -> http://zdravotnici.sk/#/dashboard/

*/

import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
import Layout from '../layouts/Layout'
import Default from '../layouts/BlankLayout'
import IntroLayout from '../layouts/IntroLayout'
import AuthLayout from '../layouts/AuthLayouts/AuthLayout'

/* Web presentation */
import Intro from '../views/Intro/Intro.vue'
import Documents from '../views/Documents/Documents.vue'
import FAQ from '../views/FAQ/FAQ.vue'
import About from '../views/About/About.vue'
import Terms from '../views/Pages/Terms.vue'
import DoctorsList from '../views/DoctorsList/DoctorsList.vue'
import DoctorProfile from '../views/DoctorProfile/DoctorProfile.vue'
// import DoctorProfile2 from '../views/DoctorProfile/DoctorProfile.vue'

/* Authentic View */
import SignIn from '../views/AuthPages/Default/SignIn'
import SignUp from '../views/AuthPages/Default/SignUp'
import RecoverPassword from '../views/AuthPages/Default/RecoverPassword'
import ConfirmMail from '../views/AuthPages/Default/ConfirmMail'

/* Dashboard */
/* User View */
import Profile from '../views/User/Profile'
import ProfileEdit from '../views/User/ProfileEdit'
import AddUser from '../views/User/AddUser'
import AddEmployeer from '../views/User/AddEmployeer'

/* Storage Table */
import StorageTable from '../views/Storage/StorageTable'

// OTHER
/* UIElements View */
import UiAlerts from '../views/core/UiAlerts.vue'
import UiButtons from '../views/core/UiButtons.vue'
import UiBadges from '../views/core/UiBadges.vue'
import UiBreadcrumb from '../views/core/UiBreadcrumb.vue'
import UiColors from '../views/core/UiColors.vue'
import UiTypography from '../views/core/UiTypography.vue'
import UiCards from '../views/core/UiCards.vue'
import UiCarousel from '../views/core/UiCarousel.vue'
import UiEmbedVideo from '../views/core/UiEmbedVideo.vue'
import UiGrid from '../views/core/UiGrid.vue'
import UiModal from '../views/core/UiModal.vue'
import UiListGroup from '../views/core/UiListGroup.vue'
import UiImages from '../views/core/UiImages.vue'
import UiMediaObject from '../views/core/UiMediaObject.vue'
import UiTooltips from '../views/core/UiTooltips.vue'
import UiPopovers from '../views/core/UiPopovers.vue'
import UiNotifications from '../views/core/UiNotifications.vue'
import UiTabs from '../views/core/UiTabs.vue'
import UiPagination from '../views/core/UiPagination.vue'
import UiProgressBars from '../views/core/UiProgressBars.vue'

/* Extra Pages */
import ErrorPage from '../views/Pages/ErrorPage'
import ComingSoon from '../views/Pages/ComingSoon'
import TimeLine from '../views/Pages/TimeLines'
import Pricing from '../views/Pages/Pricing'
import Pricing1 from '../views/Pages/Pricing1'
import FAQs from '../views/Pages/FAQs'

/* Tables Views */
import TablesBasic from '../views/Tables/TablesBasic'
import DataTable from '../views/Tables/DataTable'
import EditableTable from '../views/Tables/EditableTable'

import GoogleMaps from '../views/Maps/GoogleMaps'

/* Form View */
import FormLayout from '../views/Forms/FormLayout'
import FormValidates from '../views/Forms/FormValidates'
import FormSwitches from '../views/Forms/FormSwitches'
import FormRadios from '../views/Forms/FormRadios'
import FormCheckboxes from '../views/Forms/FormCheckboxes'

/* Todo */
import Callback from '../views/AuthPages/Default/Callback'

/* Plugins Views */
import DatepickerDemo from '../views/Plugins/DatepickerDemo'
import SelectDemo from '../views/Plugins/SelectDemo'
import DragDropDemo from '../views/Plugins/DragDropDemo'
import AppTreeView from '../views/Plugins/AppTreeView'

/* Other */
import NotFound from '../views/Pages/ErrorPage.vue'

Vue.use(VueRouter)

const commingRoutes = prop => [
  {
    path: '/',
    name: prop,
    meta: { auth: true },
    component: ComingSoon
  }
]

const introRoutes = prop => [
  // {
  //   path: '/',
  //   name: prop,
  //   meta: { auth: true },
  //   component: ComingSoon
  // },
  {
    path: '/intro',
    name: prop + '.intro',
    meta: { auth: true, name: 'Intro' },
    component: Intro
  },
  {
    path: '/dokumenty',
    name: prop + '.dokumenty',
    meta: { auth: true, name: 'Documents' },
    component: Documents
  },
  {
    path: '/casto-kladene-otazky',
    name: prop + '.casto-kladene-otazky',
    meta: { auth: true, name: 'FAQ' },
    component: FAQ
  },
  {
    path: '/vyhladat-doktora',
    name: prop + '.vyhladat-doktora',
    meta: { auth: true, name: 'DoctorsList' },
    component: DoctorsList
  },
  {
    path: '/profil-doktora/:doctorURL',
    name: prop + '.profil-doktora',
    meta: { auth: true, name: 'DoctorProfile' },
    component: DoctorProfile,
    props: true
  },
  // {
  //   path: '/profil-doktora-v2/:doctorURL',
  //   name: prop + '.profil-doktora-v2',
  //   meta: { auth: true, name: 'DoctorProfile' },
  //   component: DoctorProfile2,
  //   props: true
  // },
  {
    path: '/preco-sa-registrovat',
    name: prop + '.preco-sa-registrovat',
    meta: { auth: true, name: 'About' },
    component: About
  },
  {
    path: '/pravidla-a-podmienky',
    name: prop + '.pravidla-a-podmienky',
    meta: { auth: true, name: 'Terms' },
    component: Terms
  }
]

// const dashboardChildRoutes = (prop) => [
//   {
//     path: '/',
//     name: prop + '.home-1',
//     meta: { auth: true, name: 'Home 1' },
//     component: Dashboard
//   }
// ]

const coreChildRoute = prop => [
  {
    path: 'ui-colors',
    name: prop + '.color',
    meta: { auth: true, name: 'Colors' },
    component: UiColors
  },
  {
    path: 'ui-typography',
    name: prop + '.typography',
    meta: { auth: true, name: 'Typography' },
    component: UiTypography
  },
  {
    path: 'ui-alerts',
    name: prop + '.alert',
    meta: { auth: true, name: 'Alert' },
    component: UiAlerts
  },
  {
    path: 'ui-buttons',
    name: prop + '.button',
    meta: { auth: true, name: 'Button' },
    component: UiButtons
  },
  {
    path: 'ui-breadcrumb',
    name: prop + '.breadcrumb',
    meta: { auth: true, name: 'Breadcrumb' },
    component: UiBreadcrumb
  },
  {
    path: 'ui-badges',
    name: prop + '.badges',
    meta: { auth: true, name: 'Badges' },
    component: UiBadges
  },
  {
    path: 'ui-cards',
    name: prop + '.cards',
    meta: { auth: true, name: 'Card' },
    component: UiCards
  },
  {
    path: 'ui-carousel',
    name: prop + '.carousel',
    meta: { auth: true, name: 'Carousel' },
    component: UiCarousel
  },
  {
    path: 'ui-grid',
    name: prop + '.grid',
    meta: { auth: true, name: 'Grid' },
    component: UiGrid
  },
  {
    path: 'ui-embed-video',
    name: prop + '.embed-video',
    meta: { auth: true, name: 'Embed Video' },
    component: UiEmbedVideo
  },
  {
    path: 'ui-modal',
    name: prop + '.modal',
    meta: { auth: true, name: 'Model' },
    component: UiModal
  },
  {
    path: 'ui-listgroup',
    name: prop + '.listgroup',
    meta: { auth: true, name: 'List Group' },
    component: UiListGroup
  },
  {
    path: 'ui-images',
    name: prop + '.images',
    meta: { auth: true, name: 'Image' },
    component: UiImages
  },
  {
    path: 'ui-media-object',
    name: prop + '.media-object',
    meta: { auth: true, name: 'Media Object' },
    component: UiMediaObject
  },
  {
    path: 'ui-pagination',
    name: prop + '.pagination',
    meta: { auth: true, name: 'Paginations' },
    component: UiPagination
  },
  {
    path: 'ui-progressbars',
    name: prop + '.progressbars',
    meta: { auth: true, name: 'Progressbar' },
    component: UiProgressBars
  },
  {
    path: 'ui-tooltips',
    name: prop + '.tooltips',
    meta: { auth: true, name: 'Tooltip' },
    component: UiTooltips
  },
  {
    path: 'ui-popovers',
    name: prop + '.popovers',
    meta: { auth: true, name: 'Popover' },
    component: UiPopovers
  },
  {
    path: 'ui-notifications',
    name: prop + '.notifications',
    meta: { auth: true, name: 'Notification' },
    component: UiNotifications
  },
  {
    path: 'ui-tabs',
    name: prop + '.tabs',
    meta: { auth: true, name: 'Tabs' },
    component: UiTabs
  }
]
const formChildRoute = prop => [
  {
    path: 'form-layout',
    name: prop + '.layout',
    meta: { auth: true, name: 'Layout' },
    component: FormLayout
  },
  {
    path: 'form-validate',
    name: prop + '.validate',
    meta: { auth: true, name: 'Validate' },
    component: FormValidates
  },
  {
    path: 'form-switches',
    name: prop + '.switch',
    meta: { auth: true, name: 'Switch' },
    component: FormSwitches
  },
  {
    path: 'form-radios',
    name: prop + '.radio',
    meta: { auth: true, name: 'Radio' },
    component: FormRadios
  },
  {
    path: 'form-checkboxes',
    name: prop + '.checkbox',
    meta: { auth: true, name: 'Checkbox' },
    component: FormCheckboxes
  }
]

const tableChildRoute = prop => [
  {
    path: 'tables-basic',
    name: prop + '.basic',
    meta: { auth: true, name: 'Basic' },
    component: TablesBasic
  },
  {
    path: 'data-table',
    name: prop + '.dataTable',
    meta: { auth: true, name: 'Datatable' },
    component: DataTable
  },
  {
    path: 'editable',
    name: prop + '.editable',
    meta: { auth: true, name: 'Editable' },
    component: EditableTable
  }
]

const authChildRoutes = prop => [
  {
    path: 'prihlasenie',
    name: prop + '.prihlasenie',
    meta: { auth: true },
    component: SignIn
  },
  {
    path: 'registracia',
    name: prop + '.registracia',
    meta: { auth: true },
    component: SignUp
  },
  {
    path: 'reset-hesla',
    name: prop + '.reset-hesla',
    meta: { auth: true },
    component: RecoverPassword
  },
  {
    path: 'potvrdenie-emailu',
    name: prop + '.potvrdenie-emailu',
    meta: { auth: true },
    component: ConfirmMail
  }
]

const mapChildRoute = prop => [
  {
    path: 'google-maps',
    name: prop + '.google',
    meta: { auth: true, name: 'Google map' },
    component: GoogleMaps
  }
]

const defaultlayout = prop => [
  {
    path: 'timeline',
    name: prop + '.timeline',
    meta: { auth: true, name: 'Timeline' },
    component: TimeLine
  },
  {
    path: 'pricing',
    name: prop + '.pricing',
    meta: { auth: true, name: 'Pricing' },
    component: Pricing
  },
  {
    path: 'pricing-1',
    name: prop + '.pricing1',
    meta: { auth: true, name: 'Pricing 1' },
    component: Pricing1
  },
  {
    path: 'faq',
    name: prop + '.faq',
    meta: { auth: true, name: 'Faq' },
    component: FAQs
  }
]

const pagesChildRoutes = prop => [
  {
    path: 'error/:code',
    name: prop + '.error',
    meta: { auth: true },
    component: ErrorPage
  }
]
const userChildRoute = prop => [
  {
    path: '/',
    name: prop + '',
    meta: { auth: true, name: 'StorageTable' },
    component: StorageTable
  },
  {
    path: 'profil',
    name: prop + '.profil',
    meta: { auth: true, name: 'Profile' },
    component: Profile
  },
  {
    path: 'upravit-profil',
    name: prop + '.upravit-profil',
    meta: { auth: true, name: 'Edit Profile' },
    component: ProfileEdit
  },
  {
    path: 'pridat-profil',
    name: prop + '.pridat-profil',
    meta: { auth: true, name: 'Add User' },
    component: AddUser
  },
  {
    path: 'pridat-zamestnavatela',
    name: prop + '.pridat-zamestnavatela',
    meta: { auth: true, name: 'Add Employeer' },
    component: AddEmployeer
  },

  {
    path: 'zoznam-skladovych-kariet',
    name: prop + '.zoznam-skladovych-kariet',
    meta: { auth: true, name: 'StorageTable' },
    component: StorageTable
  }
]

// const storageChildRoute = (prop) => [
//   {
//     path: 'skladova-tabulka',
//     name: 'skladova-tabulka',
//     meta: { auth: true, name: 'StorageTable' },
//     component: StorageTable
//   },
//   {
//     path: 'upravit-profil',
//     name: prop + '.upravit-profil',
//     meta: { auth: true, name: 'Edit Profile' },
//     component: ProfileEdit
//   }
// ]

const pluginsChildRoute = prop => [
  {
    path: 'datepicker',
    name: prop + '.datepicker',
    meta: { auth: true, name: 'Datepicker' },
    component: DatepickerDemo
  },
  {
    path: 'select',
    name: prop + '.select',
    meta: { auth: true, name: 'Select' },
    component: SelectDemo
  },
  {
    path: 'draggable',
    name: prop + '.draggable',
    meta: { auth: true, name: 'Draggable' },
    component: DragDropDemo
  },
  {
    path: 'treeview',
    name: prop + '.treeview',
    meta: { auth: true, name: 'Tree View' },
    component: AppTreeView
  }
]

const routes = [
  {
    path: '/',
    name: 'intro',
    component: Default,
    meta: { auth: true },
    children: commingRoutes('intro')
  },
  {
    path: '/intro',
    name: 'intro',
    component: IntroLayout,
    meta: { auth: true },
    children: introRoutes('intro')
  },
  {
    path: '/core',
    name: 'core',
    component: Layout,
    meta: { auth: true },
    children: coreChildRoute('core')
  },
  {
    path: '/table',
    name: 'table',
    component: Layout,
    meta: { auth: true },
    children: tableChildRoute('table')
  },

  {
    path: '/auth',
    name: 'auth',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth')
  },
  {
    path: '/pages',
    name: 'pages',
    component: Default,
    meta: { auth: true },
    children: pagesChildRoutes('default')
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout,
    meta: { auth: true },
    children: defaultlayout('extra-pages')
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Layout,
    meta: { auth: true },
    children: userChildRoute('dashboard')
  },
  {
    path: '/map',
    name: 'map',
    component: Layout,
    meta: { auth: true },
    children: mapChildRoute('map')
  },
  {
    path: '/form',
    name: 'form',
    component: Layout,
    meta: { auth: true },
    children: formChildRoute('form')
  },
  {
    path: '/plugins',
    name: 'plugins',
    component: Layout,
    meta: { auth: true },
    children: pluginsChildRoute('plugins')
  },
  {
    path: '/callback',
    name: 'callback',
    meta: { auth: false },
    component: Callback
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

export default router
