<template>
  <div>
    <div class="h--gradient h--margin-05 margin-top-1">
      <h1 class="beta">Obnovenie hesla</h1>
    </div>
    <div class="p--margin-06 p--size-16">
      <p>Zadaj tvoju emailovu adresu a my ti pošleme email s inštrukciami na obvnovu hesla.</p>
    </div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">

        <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
          <div class="form-group ">
            <label for="emailInput">Emailová adresa</label>
            <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                  id="emailInput" aria-describedby="emailHelp"
                  v-model="user.email" placeholder="Zadaj email" required>
            <div class="invalid-feedback">
              <span>{{ errors[0] }}</span>
            </div>
          </div>
        </ValidationProvider>

        <div class="d-inline-block w-100 margin-top-1">
          <button type="submit" class="btn btn-primary btn-lg float-right">Obnoviť heslo</button>
        </div>

      </form>
    </ValidationObserver>
  </div>
</template>
<script>
export default {
  name: 'RecoverPassword',
  data: () => ({
    user: {
      email: ''
    }
  }),
  methods: {
    onSubmit () {
      console.log(this.user)
    }
  }
}
</script>
