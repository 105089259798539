<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form novalidate @submit.prevent="handleSubmit(sendForm)">
        <b-row>
          <b-col lg="3">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Nový používateľ</h4>
              </template>
              <template v-slot:body>
                <b-form-group class="txt-c txt-md-l">
                  <div class="add-img-user profile-img-edit">
                    <b-img class="profile-pic height-150 width-150" fluid :src="user.profile_image" alt="profile-pic" />
                    <input type="hidden" v-model="user.profile_image" name="profile_image" id="profile_image">
                    <div class="p-image">
                      <b-button variant="none" class="upload-button iq-bg-primary position-relative">
                        <input type="file" @change="previewImage" class="h-100 position-absolute" accept="image/*" style="opacity: 0;" />
                        Nahrať fotku
                      </b-button>
                    </div>
                  </div>
                  <div class="img-extension mt-3">
                    <div class="d-inline-block align-items-center">
                      <span>Povolené formáty: </span>
                      <b-link href="javascript:void(0);">.jpg</b-link>
                      <b-link href="javascript:void(0);">.png</b-link>
                      <b-link href="javascript:void(0);">.jpeg</b-link>
                    </div>
                  </div>
                </b-form-group>
                <!-- <b-form-group
                  label="Facebook Odkaz:"
                  label-for="furl"
                >
                  <b-form-input v-model="user.social.facebook_url" id="furl" name="furl" placeholder="Facebook Odkaz"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Twitter Odkaz:"
                  label-for="turl"
                >
                  <b-form-input v-model="user.social.twitter_url" id="turl" name="turl" placeholder="Twitter Odkaz"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Instagram Odkaz:"
                  label-for="instaurl"
                >
                  <b-form-input v-model="user.social.instagram_url" id="instaurl" name="instaurl" placeholder="Instagram Odkaz"></b-form-input>
                </b-form-group>
                <b-form-group
                  label="Linkedin Odkaz:"
                  label-for="lurl"
                >
                  <b-form-input v-model="user.social.linkedin_url" id="lurl" name="lurl" placeholder="Linkedin Odkaz"></b-form-input>
                </b-form-group> -->
              </template>
            </iq-card>
          </b-col>
          <b-col lg="9">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Typ používateľa</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-12 col-sm-6 col-lg-4"
                                  label-for="typeOfUser"
                                  label="Typ osoby:">
                      <ValidationProvider name="Typ osoby" rules="required" v-slot="{ errors }">
                        <span class="select-form">
                          <b-form-select v-model="user.typeOfUser" size="lg" plain :options="typesOfUser" id="typeOfUser" name="typeOfUser" @change="changeProfileIcon" :class="(errors.length > 0 ? ' is-invalid' : '')">
                          </b-form-select>
                        </span>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-lg-4"
                                  label-for="typeOfMedic"
                                  label="Typ zdravotnika:" v-if="user.typeOfUser === 'Z'">
                      <span class="select-form">
                        <b-form-select v-model="user.typeOfMedic" size="lg" plain :options="typesOfMedic" id="typeOfMedic" name="typeOfMedic" @change="changeProfileIcon">
                        </b-form-select>
                      </span>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-lg-4" label="Špecializácia:" label-for="typeOfSpecialization" v-if="user.typeOfMedic === 'L'">
                      <ValidationProvider name="Špecializácia" v-slot="{ errors }">
                        <span class="select-form">
                          <b-form-select v-model="user.specialization" size="lg" plain :options="typesOfSpecialization" id="typeOfSpecialization" name="typeOfSpecialization" placeholder="Špecializácia">
                          </b-form-select>
                        </span>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 margin-top-1" v-if="user.typeOfUser === 'Z'">
                      <b-form-checkbox v-model="user.employer">Zamestnávateľ</b-form-checkbox>
                    </b-form-group>
                  </b-row>
                </div>
              </template>
            </iq-card>

            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Kontaktné údaje</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <div class="col-12 col-sm-6">
                      <b-row class="gutter-10">
                        <b-form-group class="col-4 col-sm-4" label="Titul:" label-for="degree">
                          <ValidationProvider name="Titul" v-slot="{ errors }">
                            <b-form-input v-model="user.degree" type="text" name="degree" id="degree" placeholder="Titul" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                        <b-form-group class="col-8 col-sm-8" label="Meno:" label-for="firstname">
                          <ValidationProvider name="Meno" rules="required" v-slot="{ errors }">
                            <b-form-input v-model="user.firstname" type="text" name="firstname" id="firstname" placeholder="Meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                        </b-form-group>
                      </b-row>
                    </div>
                    <b-form-group class="col-12 col-sm-6" label="Priezvisko:" label-for="lastname">
                      <ValidationProvider name="Priezvisko" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.lastname" type="text" name="lastname" id="lastname" placeholder="Priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <b-row>
                    <b-form-group class="col-12 col-sm-6"
                                  label="Ulica:"
                                  label-for="street"
                    >
                      <ValidationProvider name="Ulica" v-slot="{ errors }">
                        <b-form-input v-model="user.street" type="text" name="street" id="street" placeholder="Ulica"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-12 col-sm-6"
                                  label="PSČ:"
                                  label-for="psc"
                    >
                      <ValidationProvider name="PSČ" v-slot="{ errors }">
                        <b-form-input v-model="user.psc" type="text" name="psc" id="psc" placeholder="PSČ"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-12 col-sm-6"
                                  label="Mesto:"
                                  label-for="city"
                    >
                      <ValidationProvider name="Mesto" v-slot="{ errors }">
                        <b-form-input  v-model="user.city" type="text" name="city" id="city" placeholder="Mesto"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <!-- <b-form-group class="col-12 col-sm-6"
                                  label="Názov spoločnosti:"
                                  label-for="cname"
                    >
                      <b-form-input v-model="user.company_name" type="text" name="cname" id="cname" placeholder="Názov spoločnosti"></b-form-input>
                    </b-form-group> -->

                    <b-form-group class="col-12 col-sm-6" label="Telefónne číslo:" label-for="phone">
                      <ValidationProvider name="Telefónne číslo" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.phone" type="text" name="phone" id="phone" placeholder="Telefónne číslo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6" label="Pohlavie:" label-class="d-block">
                      <b-form-radio inline v-model="user.gender" value="muž" name="gender" id="gender-male">Muž</b-form-radio>
                      <b-form-radio inline v-model="user.gender" value="žena" name="gender" id="gender-female">Žena</b-form-radio>
                    </b-form-group>

                  </b-row>
                  <!-- <hr> -->
                </div>
              </template>
            </iq-card>
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Prihlasovacie údaje</h4>
              </template>
              <template v-slot:body>
                <b-row>
                  <b-form-group class="col-12 col-sm-6" label="Používatelské meno:" label-for="username">
                    <ValidationProvider name="Používatelské meno" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="user.username" type="text" name="username" id="username" placeholder="Používatelské meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-12 col-sm-6" label="Email:" label-for="email">
                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                      <b-form-input v-model="user.email" type="email" name="email" id="email" placeholder="Email" disabled :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-12 col-sm-6" label="Heslo:" label-for="password">
                    <ValidationProvider name="Heslo" rules="required|confirmed:repeat_password" v-slot="{ errors }">
                      <b-form-input v-model="user.password" type="password" id="password" name="password" placeholder="Password" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                  <b-form-group class="col-12 col-sm-6" label="Zopakuj heslo:" label-for="repeatpassword">
                    <ValidationProvider vid="repeat_password" name="Potvrď heslo" rules="required" v-slot="{ errors }">
                      <b-form-input v-model="user.repeat_password" type="password" id="repeatpassword" name="repeatpassword" placeholder="Potvrď heslo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                      <div class="invalid-feedback">
                        <span>{{ errors[0] }}</span>
                      </div>
                    </ValidationProvider>
                  </b-form-group>
                </b-row>
              </template>
            </iq-card>
						<div class="text-right margin-top-2 margin-bottom-4">
							<b-button variant="primary" class="btn-lg" type="submit">Pridať nového používateľa</b-button>
						</div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
// import router from '@/router'
// import axios from '@/services/index'

export default {
  name: 'AddUser',
  mounted () {
    xray.index()
  },
  data () {
    return {
      user: {
        degree: '',
        firstname: '',
        lastname: '',
        username: '',
        email: 'jakubtursky769@gmail.com',
        profile_image: require('../../assets/images/user/08.jpg'),
        password: '',
        street: '',
        psc: '',
        city: '',
        gender: '',
        phone: '',
        typeOfUser: '',
        typeOfMedic: '',
        specialization: '',
        employer: false,
        social: {
          facebook_url: '',
          twitter_url: '',
          linkedin_url: '',
          instagram_url: ''
        }
      },
      typesOfUser: [
        { id: 0, value: 'Z', text: 'Z - zdravotnik' },
        { id: 1, value: 'O', text: 'O - osoba, pacient' }
      ],
      typesOfMedic: [
        { id: 0, value: 'L', text: 'L - lekár' },
        { id: 1, value: 'S', text: 'S - zdravotná sestra' },
        { id: 2, value: 'I', text: 'I - iný zdravotnicky pracovník' }
      ],
      typesOfSpecialization: [
        { id: 0, value: 'kardiochirurg', text: 'Kardiochirurg' },
        { id: 1, value: 'neurolog', text: 'Neurológ' },
        { id: 2, value: 'kožný', text: 'Kožný' }
      ]
    }
  },
  computed: {
    fullName: function () {
      return this.user.firstname + ' ' + this.user.lastname
    }
  },
  methods: {
    changeProfileIcon: function () {
      if (this.user.type === 'O') {
        this.user.profile_image = require('../../assets/images/user/14.jpg')
      } else {
        if (this.user.typeOfMedic === 'L') {
          this.user.profile_image = require('../../assets/images/user/08.jpg')
        } else if (this.user.typeOfMedic === 'S') {
          this.user.profile_image = require('../../assets/images/user/07.jpg')
        } else if (this.user.typeOfMedic === 'I') {
          this.user.profile_image = require('../../assets/images/user/09.jpg')
        } else {
          this.user.profile_image = require('../../assets/images/user/08.jpg')
        }
      }
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    sendForm () {
      console.log(this.user)

      // if (this.user.employer) {
      //   router.push('pridat-zamestnavatela')
      // }

      // axios.post('/contact', this.user)
      //   .then((res) => {
      //     console.log(res)
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   }).finally(() => {

      //   })
      return false
    }

  }
}
</script>

<style lang="scss">
.profile-img-edit {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.iq-bg-primary {
	cursor: pointer;
	&:hover {
		background: darken(#ceebee, 10%) !important;
	}
}
</style>
