<template>
  <b-container fluid>
    <div class="row margin-top-8">
      <div class="col-12 position-static">
        <div class="intro__header">
          <div class="row">
            <div class="col-12 col-md-12">
              <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
                <template v-slot:body>
                  <div class="doctor-details-block">
                    <div class="doctor-profile text-center original-photo" v-if="doctor.profile">
                      <b-img :src="doctor.profile" alt="profile-img" class="avatar-130 img-fluid"/>
                    </div>
                    <div class="doctor-profile text-center" v-if="!doctor.profile">
                      <b-img :src="profileDefault" alt="profile-img" class="avatar-130 img-fluid"/>
                    </div>
                    <div class="text-center doctor-text-block h--margin-06">
                      <h1 class="gamma" v-if="doctor.name"><strong>{{doctor.name}}</strong></h1>
                      <div class="p--margin-05" v-if="doctor.specialist">
                        <p>{{doctor.specialist}}</p>
                        <p><span class="a-link__text">{{doctor.city}}</span></p>
                      </div>
                      <div class="margin-top-2" v-if="doctor.about">
                        <div class="content-width-600 margin-center">
                          <p class="mb-0"> {{ doctor.about }} </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </iq-card>
            </div>
            <div class="col-12 col-md-6">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Bližšie informácie</h4>
                    </div>
                  </div>
                  <div class="iq-card-body">
                    <div class="about-info m-0 p-0">
                      <div class="row margin-bottom-05" v-if="doctor.company">
                        <div class="col-4">Nazov organizácie:</div>
                        <div class="col-8">{{ doctor.company }}</div>
                      </div>
                      <div class="row margin-bottom-05" v-if="doctor.address">
                        <div class="col-4">Adresa:</div>
                        <div class="col-8"> {{ doctor.address.street }} <br> {{ doctor.address.psc }} {{ doctor.address.city }}</div>
                      </div>
                      <div class="row margin-bottom-05" v-if="doctor.email">
                        <div class="col-4">Email:</div>
                        <div class="col-8"><a :href="'mailto:'+ doctor.email" class="a-link a-link-0"> <span class="a-link__text a-link__text--size-16 a-link--hover-underline">{{ doctor.email }}</span></a></div>
                      </div>
                      <div class="row margin-bottom-05" v-if="doctor.phone">
                        <div class="col-4">Telefon:</div>
                        <div class="col-8">
                          <a :href="'tel:' + phone" class="a-link a-link--phone a-link-0" v-for="phone in doctor.phonesList" :key="phone.id"><span class="a-link__text a-link__text--size-16 a-link--hover-underline"> {{ phone }} </span></a>
                        </div>
                      </div>
                      <div class="row margin-bottom-05" v-if="doctor.ico">
                        <div class="col-4">IČO:</div>
                        <div class="col-8">{{ doctor.ico }}</div>
                      </div>
                      <div class="row margin-bottom-05" v-if="doctor.dic">
                        <div class="col-4">DIČ:</div>
                        <div class="col-8">{{ doctor.dic }}</div>
                      </div>
                    </div>
                  </div>
                </template>
              </iq-card>
            </div>

            <div class="col-12 col-md-6" v-if="news">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Aktuálne oznamy</h4>
                    </div>
                  </div>
                  <div class="iq-card-body">
                    <div class="news-group p--white h--white h--font-medium">

                      <b-card
                          text-variant="white"
                          bg-variant="primary"
                          v-for="item in news" :key="item.id"
                        >
                        <b-card-title class="text-white"><div class="zeta">{{ item.title }}</div></b-card-title>
                          <blockquote class="blockquote mb-0">
                            <p class="font-size-14">{{ item.description }}</p>
                            <footer class="blockquote-footer text-white font-size-12">Pridané: {{ item.created_date }} </footer>
                          </blockquote>
                        </b-card>
                    </div>
                  </div>
                </template>
              </iq-card>
            </div>

            <div class="col-12" v-if="ordinaryHours">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Ordinačné hodiny</h4>
                    </div>
                  </div>
                  <b-table-simple responsive>
                    <!-- <b-thead>
                      <b-th>Deň</b-th>
                      <b-th v-for="headCell in ordinaryHours" :key="headCell.id"> Hodina
                      </b-th>
                    </b-thead> -->
                    <b-tbody>
                      <b-tr v-for="row in ordinaryHours" :key="row.id">
                        <b-th>{{ row.day }}</b-th>
                        <b-td v-for="cell in row.hours" :key="cell.id">
                          {{ cell }}
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </template>
              </iq-card>
            </div>

            <div class="col-12 col-md-6" v-if="servicesList">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Poskytované služby</h4>
                    </div>
                  </div>
                  <div class="iq-card-body">
                      <div class="btn-service-group">
                        <span class="btn btn-outline-primary btn--service margin-bottom-08 margin-right-08" v-for="service in servicesList" :key="service.id">
                          {{ service.title }}
                        </span>
                      </div>
                  </div>
                </template>
              </iq-card>
            </div>

            <div class="col-12 col-md-6" v-if="priceDocument">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Cennik</h4>
                    </div>
                  </div>
                  <div class="iq-card-body">
                    <ul class="speciality-list m-0 p-0 p--size-12">
                      <li class="d-flex mb-4 align-items-center">
                        <a class="user-img img-fluid"><a :href="priceDocument" class="iq-bg-primary"><i class="ri-award-fill"></i></a></a>
                        <a :href="priceDocument" class="media-support-info ml-3">
                          <h6>Cenník zákrokov</h6>
                          <p class="mb-0">Na stiahnutie</p>
                        </a>
                      </li>

                    </ul>
                  </div>
                </template>
              </iq-card>
            </div>

            <div class="col-12">
              <iq-card body-class="iq-card-body">
                <template v-slot:body>
                  <div class="iq-card-header d-flex justify-content-between">
                    <div class="iq-header-title">
                      <h4 class="card-title">Opýtajte sa doktora</h4>
                    </div>
                  </div>
                  <div class="iq-card-body">
                    <form class="email-form">
                      <div class="form-group row">
                        <label for="name" class="col-12 col-md-4 col-xl-2 col-form-label">Meno a priezvisko:</label>
                        <div class="col-12 col-md-8 col-xl-10">
                          <input type="text" id="name" name="name" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="email" class="col-12 col-md-4 col-xl-2 col-form-label">Email:</label>
                        <div class="col-12 col-md-8 col-xl-10">
                          <input type="email"  id="email" name="email" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="subject" class="col-12 col-md-4 col-xl-2 col-form-label">Predmet:</label>
                        <div class="col-12 col-md-8 col-xl-10">
                          <input type="text"  id="subject" name="subject" class="form-control">
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="message" class="col-12 col-md-4 col-xl-2 col-form-label">Správa:</label>
                        <div class="col-12 col-md-8 col-xl-10">
                          <textarea class="textarea form-control" id="message" name="message" rows="5"></textarea>
                        </div>
                      </div>
                      <div class="form-group row align-items-center pt-3 m-0 text-right">
                        <div class="d-flex flex-grow-1 align-items-center justify-content-end">
                          <div class="send-btn">
                            <button type="submit" class="btn btn-primary btn-lg">Odoslať správu</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </template>
              </iq-card>
            </div>

            <div class="margin-top-5 col-12" v-if="documents">
              <div class="delta h--gradient">Dokumenty pre pacienta na stiahnutie</div>
              <div class="row list-group-ext h--margin-0 h--font-bold">
                <div class="col-12 col-sm-6 col-md-4" v-for="item in documents" :key="item.id">
                  <b-card
                    :title="item.title"
                    class="iq-mb-3"
                    :header="item.category"
                  >
                    <b-card-text>{{item.description}}</b-card-text>
                    <b-button href="#" variant="primary">Stiahnuť</b-button>
                  </b-card>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-4 position-static d-none d-md-block">
              <transition name="slide-fade">
                <img :src="require('../../assets/images/intro/doctor-activity.svg')"  alt="" class="img-fluid intro__img intro__img--ext">
              </transition>
            </div>
          </div>
        </div>

      </div>
    </div>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
export default {
  name: 'Profil',
  mounted () {
    xray.index()
  },
  data () {
    return {
      profileDefault: require('../../assets/images/user/15.jpg'),
      doctor: {
        background: require('../../assets/images/page-img/profile-bg.jpg'),
        profile: require('../../assets/images/doctor/doktorka.jpg'),
        name: 'Mudr. Jana Kováčová',
        specialist: 'Kardiochirurg',
        about: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis facilis esse provident tenetur dolor, eos dignissimos quo incidunt quia minima maiores placeat quidem deleniti nesciunt, ipsam blanditiis amet, suscipit delectus.',
        email: 'nikjone@demoo.com',
        phonesList: ['+421 351 256 120', '+421 351 216 123'],
        company: 'Collegium Med, s.r.o',
        ico: '457891232',
        dic: '956123456',
        address: {
          street: 'A. Sladkoviča 13',
          city: 'Žiar nad Hronom',
          psc: '96601'
        }
      },
      news: [
        {
          title: 'Dovolenka',
          description: 'Dovolenka od 15.6 2021 - 22.6.2021',
          created_date: '25 01. 2021'
        },
        {
          title: 'Dlhý nadpis oznámenia Dlhý nadpis oznámenia Dlhý nadpis oznámenia',
          description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
          created_date: '25 01. 2021'
        },
        {
          title: 'Oznámenčie č. 3',
          description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit.',
          created_date: '25 01. 2021'
        }
      ],
      ordinaryHours: [
        {
          day: 'Pondelok',
          hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva']
        },
        {
          day: 'Utorok',
          hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva']
        },
        {
          day: 'Streda',
          hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva']
        },
        {
          day: 'Štvrtok',
          hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva']
        },
        {
          day: 'Piatok',
          hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva']
        }
      ],
      documents: [
        {
          id: 1,
          title: 'Charta práv pacienta',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Zdravotnictvo'
        },
        {
          id: 2,
          title: 'Informácie o právach',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Lieky'
        },
        {
          id: 3,
          title: 'Informácie o povinnostiach',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Právo'
        }
      ],
      priceDocument: 'odkaz-na-dokument-pdf',
      servicesList: [
        { id: 0, title: 'Názov služby č.1' },
        { id: 1, title: 'Názov služby č.2' },
        { id: 2, title: 'Názov služby č.3' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/light/scss/profile-detail";
</style>
