<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group ">
          <label for="email">Emailová adresa</label>
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="email" name="email" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Zadaj email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group">
          <label for="password">Heslo</label>
          <router-link to="/auth/reset-hesla" class="float-right">
            Zabudnuté heslo ?
          </router-link>
          <input type="password"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="password" name="password"
                 v-model="user.password" placeholder="Heslo" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div class="d-inline-block w-100">
        <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
          <input type="checkbox" class="custom-control-input" :id="formType">
          <label class="custom-control-label" :for="formType">Remember Me</label>
        </div> -->
        <button type="submit" class="btn btn-primary btn-lg float-right">Prihlásiť sa</button>
      </div>
      <social-login-form></social-login-form>
      <div class="sign-info margin-bottom-05">
        <div class="p--margin-0 margin-bottom-05 text-center">
          <p>Nemáš ešte účet ?</p>
        </div>
        <router-link to="/auth/registracia" class="iq-waves-effect pr-4">
          <span class="btn btn-primary btn-lg btn-responsive">Registrovať sa</span>
        </router-link>
          <!-- <span class="dark-color d-inline-block line-height-2">
            Nemáš ešte účet ?
            <router-link to="/auth/registracia" class="iq-waves-effect pr-4">
              Registruj sa
            </router-link>
          </span> -->
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import SocialLoginForm from './SocialLoginForm'
import { mapGetters } from 'vuex'

export default {
  name: 'SignInForm',
  components: { SocialLoginForm },
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    }
  }),
  mounted () {
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      stateUsers: 'Setting/usersState'
    })
  },
  methods: {
    onSubmit () {
      this.login()
    },
    login () {
      this.$router.push({ name: 'dashboard' })
    }
  }
}
</script>
