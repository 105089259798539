<template>
  <div class="section intro margin-top-4">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header">
            <div class="row">
              <div class="col-12 col-md-12">
                <iq-card body-class="iq-card-body pl-0 pr-0 pt-0">
                  <template v-slot:body>
                    <div class="doctor-details-block">
                      <div class="doctor-profile text-center original-photo" v-if="doctor.profile">
                        <b-img :src="doctor.profile" alt="profile-img" class="avatar-130 img-fluid"/>
                      </div>
                      <div class="doctor-profile text-center" v-if="!doctor.profile">
                        <b-img :src="profileDefault" alt="profile-img" class="avatar-130 img-fluid"/>
                      </div>
                      <div class="text-center doctor-text-block h--margin-06">
                        <h1 class="gamma" v-if="doctor.name"><strong>{{doctor.name}}</strong></h1>
                        <div class="p--margin-05" v-if="doctor.specialist">
                          <p>{{doctor.specialist}}</p>
                          <p><span class="a-link__text">{{doctor.city}}</span></p>
                        </div>
                        <div class="margin-top-2" v-if="doctor.about">
                          <div class="content-width-600 margin-center">
                            <p class="mb-0"> {{ doctor.about }} </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </iq-card>
              </div>
              <div class="col-12">
                <iq-card body-class="iq-card-body">
                  <template v-slot:body>
                      <tab-nav :pills="true" class="nav-fill mb-3" id="pills-tab-1">
                        <tab-nav-items :active="true" id="pills-address-tab-fill" href="#pills-address-fill" ariaControls="pills-address-fill" role="tab" :ariaSelected="true" title="Kontaktné údaje" />
                        <tab-nav-items :active="false" id="pills-news-tab-fill" href="#pills-news-fill" ariaControls="pills-news-fill" role="tab" :ariaSelected="false" title="Aktuálne oznamy" />
                        <tab-nav-items :active="false" id="pills-hourse-tab-fill" href="#pills-hourse-fill" ariaControls="pills-hourse-fill" role="tab" :ariaSelected="false" title="Ordinačné hodiny" />
                        <tab-nav-items :active="false" id="pills-services-tab-fill" href="#pills-services-fill" ariaControls="pills-services-fill" role="tab" :ariaSelected="false" title="Poskytované služby" />
                        <tab-nav-items :active="false" id="pills-price-tab-fill" href="#pills-price-fill" ariaControls="pills-price-fill" role="tab" :ariaSelected="false" title="Cennik" />
                        <tab-nav-items :active="false" id="pills-documents-tab-fill" href="#pills-documents-fill" ariaControls="pills-documents-fill" role="tab" :ariaSelected="false" title="Dokumenty" />
                      </tab-nav>
                      <tab-content id="pills-tabContent-1" class="mt-0">
                        <tab-content-item :active="true" id="pills-address-fill" aria-labelled-by="pills-address-tab-fill">
                          <div class="iq-card-body">
                            <div class="about-info m-0 p-0">
                              <div class="row margin-bottom-05">
                                <div class="col-4">Nazov organizácie:</div>
                                <div class="col-8">{{ doctor.company }}</div>
                              </div>
                              <div class="row margin-bottom-05">
                                <div class="col-4">Adresa:</div>
                                <div class="col-8"> {{ doctor.address.street }} <br> {{ doctor.address.psc }} {{ doctor.address.city }}</div>
                              </div>
                              <div class="row margin-bottom-05">
                                <div class="col-4">Email:</div>
                                <div class="col-8"><a :href="'mailto:'+ doctor.email" class="a-link a-link-0"> <span class="a-link__text a-link__text--size-16 a-link--hover-underline">{{ doctor.email }}</span></a></div>
                              </div>
                              <div class="row margin-bottom-05">
                                <div class="col-4">Telefon:</div>
                                <div class="col-8">
                                  <a :href="'tel:' + phone" class="a-link a-link--phone a-link-0" v-for="phone in doctor.phonesList" :key="phone.id"><span class="a-link__text a-link__text--size-16 a-link--hover-underline"> {{ phone }} </span></a>
                                </div>
                              </div>
                              <div class="row margin-bottom-05">
                                <div class="col-4">IČO:</div>
                                <div class="col-8">{{ doctor.ico }}</div>
                              </div>
                              <div class="row margin-bottom-05">
                                <div class="col-4">DIČ:</div>
                                <div class="col-8">{{ doctor.dic }}</div>
                              </div>
                            </div>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="pills-news-fill" aria-labelled-by="pills-news-tab-fill">
                          <div class="iq-card-body">
                            <div class="news-group p--white h--white h--font-medium">

                              <b-card
                                  text-variant="white"
                                  bg-variant="primary"
                                  v-for="item in news" :key="item.id"
                                >
                                <b-card-title class="text-white"><div class="zeta">{{ item.title }}</div></b-card-title>
                                  <blockquote class="blockquote mb-0">
                                    <p class="font-size-14">{{ item.description }}</p>
                                    <footer class="blockquote-footer text-white font-size-12">Pridané: {{ item.created_date }} </footer>
                                  </blockquote>
                                </b-card>
                            </div>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="pills-hourse-fill" aria-labelled-by="pills-hourse-tab-fill">
                          <div class="iq-card-body">
                            <b-table-simple responsive>
                              <b-tbody>
                                <b-tr v-for="row in ordinaryHours" :key="row.id">
                                  <b-th>{{ row.day }}</b-th>
                                  <b-td v-for="cell in row.hours" :key="cell.id">
                                    {{ cell }}
                                  </b-td>
                                </b-tr>
                              </b-tbody>
                            </b-table-simple>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="pills-services-fill" aria-labelled-by="pills-services-tab-fill">
                          <div class="iq-card-body">
                              <div class="btn-service-group">
                                <span class="btn btn-outline-primary btn--service margin-bottom-08 margin-right-08" v-for="service in servicesList" :key="service.id">
                                  {{ service.title }}
                                </span>
                              </div>
                          </div>
                        </tab-content-item>
                        <tab-content-item :active="false" id="pills-price-fill" aria-labelled-by="pills-price-tab-fill">
                          <div class="iq-card-body">
                            <ul class="speciality-list m-0 p-0 p--size-12">
                              <li class="d-flex mb-4 align-items-center" v-for="price in pricesList" :key="price.id">
                                <a class="user-img img-fluid" :href="price.url" target="_blank"><span href="#" class="iq-bg-primary"><i class="ri-price-tag-3-line"></i></span></a>
                                <a class="media-support-info ml-3" :href="price.url" target="_blank">
                                  <h6> {{ price.title }} </h6>
                                  <p class="mb-0">Na stiahnutie</p>
                                </a>
                              </li>

                            </ul>
                          </div>
                        </tab-content-item>

                        <tab-content-item :active="false" id="pills-documents-fill" aria-labelled-by="pills-documents-tab-fill">
                          <b-list-group class="list-group-ext h--margin-0 h--font-medium">
                            <b-list-group-item href="#" v-for="document in documentsList" :key="document.id" class="margin-bottom-08">
                              <h2 class="zeta list-group-item__title"> <i class="ri-file-download-line margin-right-1"></i> {{ document.title }}</h2>
                            </b-list-group-item>
                          </b-list-group>
                        </tab-content-item>
                      </tab-content>
                  </template>
                </iq-card>
              </div>

              <div class="col-12" v-if="rateList">
                <iq-card body-class="iq-card-body">
                  <template v-slot:body>
                    <div class="iq-card-header d-flex justify-content-between">
                      <div class="iq-header-title">
                        <h4 class="card-title">Hodnotenie</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <div class="row margin-bottom-1-5 margin-bottom-sm-05 align-items-center justify-content-center justify-content-sm-start txt-c txt-sm-l" v-for="item in rateList" :key="item.id">
                        <label for="name" class="col-12 col-md-4 col-xl-4 col-form-label">{{ rateListTitle[item.id] }}:</label>
                        <div class="col-12 col-md-8 col-xl-8">
                          <rate :length="5" :value="item.value" :readonly="true"/>
                        </div>
                      </div>

                      <div class="form-group row align-items-center pt-3 m-0 text-right">
                        <div class="d-flex flex-grow-1 align-items-center justify-content-center justify-content-md-start">
                          <div class="send-btn">
                            <button type="primary" class="btn btn-primary btn-lg" v-b-modal.modal-rate><i class="ri-star-line margin-right-1"></i> Pridať hodnotenie</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </iq-card>
              </div>

              <div class="col-12">
                <iq-card body-class="iq-card-body">
                  <template v-slot:body>
                    <div class="iq-card-header d-flex justify-content-between">
                      <div class="iq-header-title">
                        <h4 class="card-title">Opýtajte sa doktora</h4>
                      </div>
                    </div>
                    <div class="iq-card-body">
                      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                        <form class="email-form" ref="formQuestion" novalidate @submit.prevent="handleSubmit(onQuestionSubmit)">
                          <ValidationProvider name="Meno a priezvisko" rules="required" v-slot="{ errors }">
                            <div class="form-group row">
                              <label for="name" class="col-12 col-md-4 col-xl-2 col-form-label">Meno a priezvisko:</label>
                              <div class="col-12 col-md-8 col-xl-10">
                                <b-form-input v-model="questionForm.name" type="text" name="name" id="name" placeholder="Zadaj meno a priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                <div class="invalid-feedback">
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>

                          <ValidationProvider vid="name" name="Email" rules="required|email" v-slot="{ errors }">
                            <div class="form-group row">
                              <label for="email" class="col-12 col-md-4 col-xl-2 col-form-label">Email:</label>
                              <div class="col-12 col-md-8 col-xl-10">
                                <input type="email" v-model="questionForm.email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                                      id="email" name="email" aria-describedby="emailHelp" placeholder="Zadaj email">
                                <div class="invalid-feedback">
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>

                          <ValidationProvider name="Predmet" rules="required" v-slot="{ errors }">
                            <div class="form-group row">
                              <label for="subject" class="col-12 col-md-4 col-xl-2 col-form-label">Predmet:</label>
                              <div class="col-12 col-md-8 col-xl-10">
                                <b-form-input v-model="questionForm.subject" type="text" name="subject" id="subject" placeholder="Zadaj predmet" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                <div class="invalid-feedback">
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>

                          <ValidationProvider name="Správa" rules="required" v-slot="{ errors }">
                            <div class="form-group row">
                              <label for="message" class="col-12 col-md-4 col-xl-2 col-form-label">Správa:</label>
                              <div class="col-12 col-md-8 col-xl-10">
                                <b-form-textarea v-model="questionForm.message" class="textarea form-control" id="message" name="message" rows="5" placeholder="Zadaj správu" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-textarea>
                                <div class="invalid-feedback">
                                  <span>{{ errors[0] }}</span>
                                </div>
                              </div>
                            </div>
                          </ValidationProvider>

                          <div class="form-group row align-items-center pt-3 m-0 text-right">
                            <div class="d-flex flex-grow-1 align-items-center justify-content-end">
                              <div class="send-btn">
                                <button type="submit" class="btn btn-primary btn-lg">Odoslať správu</button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                  </template>
                </iq-card>
              </div>

              <div class="col-12 col-md-4 position-static d-none d-md-block">
                <transition name="slide-fade">
                  <img :src="require('../../assets/images/intro/doctor-activity.svg')"  alt="" class="img-fluid intro__img intro__img--ext">
                </transition>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- MODAL RATE -->
    <b-modal
      id="modal-rate"
      size="xl"
      ref="modal-rate"
      title="Pridať hodnotenie"
      ok-title="Uložiť hodnotenie"
      cancel-title="Zrušiť"
      header-bg-variant="primary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >

       <ValidationObserver ref="form" v-slot="{ handleSubmit }">
          <form ref="formRate" novalidate @submit.prevent="handleSubmit(onRateSubmit)">
            <div class="row margin-bottom-1-5 margin-bottom-sm-05 align-items-center justify-content-center justify-content-sm-start txt-c txt-sm-l" v-for="item in rateListNew" :key="item.id">
              <label for="name" class="col-12 col-md-4 col-xl-4 col-form-label">{{ rateListTitle[item.id] }}:</label>
              <div class="col-12 col-md-8 col-xl-8">
                <rate :length="5" :name="'rate_' + item.id" :value="item.value" v-model="item.value" :ratedesc="rateListDescription"/>
              </div>
            </div>
          </form>
      </ValidationObserver>
      <p v-if="addRateErrors.length" >
        <span class="text-danger" v-for="error in addRateErrors" :key="error">{{ error }}</span>
      </p>
    </b-modal>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'

export default {
  name: 'Doctors',
  props: {
    doctorFullName: {
      type: String
    }
  },
  data () {
    return {
      logo: logo,
      profileDefault: require('../../assets/images/user/15.jpg'),
      doctor: {
        background: require('../../assets/images/page-img/profile-bg.jpg'),
        profile: require('../../assets/images/doctor/doktorka.jpg'),
        name: 'Mudr. Jana Kováčová',
        specialist: 'Kardiochirurg',
        about: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Perferendis facilis esse provident tenetur dolor, eos dignissimos quo incidunt quia minima maiores placeat quidem deleniti nesciunt, ipsam blanditiis amet, suscipit delectus.',
        email: 'nikjone@demoo.com',
        phonesList: ['+421 351 256 120', '+421 351 216 123'],
        company: 'Collegium Med, s.r.o',
        ico: '457891232',
        dic: '956123456',
        address: {
          street: 'A. Sladkoviča 13',
          city: 'Žiar nad Hronom',
          psc: '96601'
        }
      },
      news: [
        { title: 'Dovolenka', description: 'Dovolenka od 15.6 2021 - 22.6.2021', created_date: '25 01. 2021' },
        { title: 'Dlhý nadpis oznámenia Dlhý nadpis oznámenia Dlhý nadpis oznámenia', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit.', created_date: '25 01. 2021' },
        { title: 'Oznámenčie č. 3', description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus repudiandae eveniet harum. Lorem ipsum dolor, sit amet consectetur adipisicing elit.', created_date: '25 01. 2021' }
      ],
      ordinaryHours: [
        { day: 'Pondelok', hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva'] },
        { day: 'Utorok', hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva'] },
        { day: 'Streda', hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva'] },
        { day: 'Štvrtok', hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva'] },
        { day: 'Piatok', hours: ['8:00 - 12:00 - kurativa', '13:00 - 15:00 - administratíva'] }
      ],
      pricesList: [
        { id: 0, title: 'Cennik zákrokov', url: 'link.pdf' },
        { id: 1, title: 'Cennik doplnkových služieb', url: 'link.pdf' }
      ],
      documentsList: [
        { id: 0, title: 'Charta práv pacienta', description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se', category: 'Zdravotnictvo' },
        { id: 1, title: 'Informácie o právach', description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se', category: 'Lieky' },
        { id: 2, title: 'Informácie o povinnostiach', description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se', category: 'Právo'
        }
      ],
      addRateErrors: [],
      rateListDescription: [
        'Veľmi neprijemný',
        'Neprijemný',
        'Priemerný',
        'Dobrý',
        'Veľmi dobrý'
      ],
      rateListTitle: [
        'Hlavný lekár',
        'Komunikácia s pacientom',
        'Dôkladnosť vyšetrenia',
        'Čakacia doba bez objednania',
        'Čakanie na objednanie',
        'Vybavenie'
      ],
      rateList: [
        { id: 0, value: 4 },
        { id: 1, value: 3 },
        { id: 2, value: 2 },
        { id: 3, value: 4 },
        { id: 4, value: 5 },
        { id: 5, value: 2 }
      ],
      rateListNew: [
        { id: 0, value: 0 },
        { id: 1, value: 0 },
        { id: 2, value: 0 },
        { id: 3, value: 0 },
        { id: 4, value: 0 },
        { id: 5, value: 0 }
      ],
      servicesList: [
        { id: 0, title: 'Názov služby č.1' },
        { id: 1, title: 'Názov služby č.2' },
        { id: 2, title: 'Názov služby č.3' }
      ],
      questionForm: {
        name: '',
        email: '',
        subject: '',
        message: ''
      }
    }
  },
  methods: {
    checkFormValidity () {
      this.addRateErrors = []

      for (let i = 0; i < this.rateListNew.length; i++) {
        if (this.rateListNew[i].value === 0) {
          this.addRateErrors.push('Vyberte všetky hodnotenia')
          break
        }
      }

      if (!this.addRateErrors.length) {
        return true
      } else {
        return false
      }
    },
    resetModal () {
      this.rateListNew = [
        { id: 0, value: 0 },
        { id: 1, value: 0 },
        { id: 2, value: 0 },
        { id: 3, value: 0 },
        { id: 4, value: 0 },
        { id: 5, value: 0 }
      ]
      this.nameState = null
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.onRateSubmit()
    },
    onRateSubmit () {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    onQuestionSubmit () {
      console.log(this.questionForm)
    }
  },
  mounted () {
    // xray.index()
  }
}
</script>

<style lang="scss" scoped>
.doctor-profile {
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	margin: 0 auto;
	border-radius: 100%;
	padding: 25px;
	position: relative;
	top: -100px;
	overflow: hidden;
	background: white;
	border: 10px solid #eff7f8;
}

.doctor-text-block {
	margin-top: -60px;
	padding: 0 30px 30px;
}

.avatar-130 {
	width: 100%;
	height: 100%;
	line-height: 1;
	object-fit: contain;
}

.original-photo {
	padding: 0;
	.avatar-130 {
		width: 100%;
		height: 100%;
		line-height: 1;
		object-fit: cover;
	}
}

.a-link--phone {
	display: inline-block;
	&:last-child {
		&:after {
			display: none;
		}
	}
	&:after {
		content: ",";
		display: inline;
		color: $color-green;
		margin: 0 5px;
	}
}

.news-group {
	max-height: 620px;
	overflow: auto;
	padding-right: 25px;
	.card {
		border-radius: 25px;
		margin-bottom: 2rem;
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.btn-service-group {
	overflow: auto;
	max-height: 250px;
}

.btn.btn--service {
	text-transform: none;
	cursor: text;
	@include font-size(12px);
	@include bp(xsmall) {
		@include font-size(14px);
	}
	&:hover {
		background-color: transparent;
		border-color: $color-green;
		color: $color-green;
	}
}

.table td {
	@include bp(rev-small) {
		@include font-size(14px);
	}
}

.table th {
	@include bp(rev-small) {
		@include font-size(14px);
	}
}

.table {
	@include bp(rev-xsmall) {
		tbody {
			display: block;
			width: 100%;
		}
		tr {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			margin-bottom: 1rem;
			border: 1px solid #dee2e6;
		}
		th {
			width: 100%;
			flex: 100% !important;
			border-top: 0 !important;
		}
		td {
			flex: 1;
		}
	}
}
</style>
