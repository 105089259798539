<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header">
            <div class="row margin-bottom-3">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <div class="header__subtitle">Zodpovieme Vaše dotazy</div>
                <h1 class="alfa header__title h--gradient">Časko kladené otázky</h1>

              </div>
            </div>

            <div class="row gutter-sm-50 p--margin-0 h--margin-0">
              <div class="col-12">
                <div class="iq-accordion career-style faq-style">
                  <iq-card class="iq-card iq-accordion-block" :class="item.active ? 'accordion-active' : ''" v-for="(item,index) in faqList" :key="index">
                    <div class="clearfix" :class="item.active ? 'active-faq' : ''">
                      <div class="container">
                        <b-row class="py-3" >
                          <b-col sm="12"><h2 class="beta d-flex align-items-center"><a class="accordion-title"><span> {{ item.title }} </span> </a></h2></b-col>
                        </b-row>
                      </div>
                    </div>
                    <div class="accordion-details">
                      <p class="mb-0">{{ item.description }} </p>
                    </div>
                  </iq-card>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center margin-top-3">
                <router-link to="auth/registracia">
                  <button class="btn btn-lg btn-primary">Zaregistrovať sa</button>
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'
import { xray } from '../../config/pluginInit'

export default {
  name: 'FAQ',
  data () {
    return {
      logo: logo,
      faqList: [
        {
          title: 'It is a long established fact that a reader will be?',
          description: 'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for \'lorem ipsum\' will uncover many web sites still in their infancy.',
          active: false
        },
        {
          title: 'Distracted by the readable content of a page whent?',
          description: 'It has survived not only five centuries, but also the leap into electronic typesetting. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.',
          active: false
        },
        {
          title: 'What is user interface kit?',
          description: 'It has survived not only five centuries, but also the leap into electronic typesetting. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.',
          active: false
        },
        {
          title: 'The readable content of a page when looking at its layout?',
          description: 'It has survived not only five centuries, but also the leap into electronic typesetting. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.',
          active: false
        },
        {
          title: 'It is a long established fact that a reader will be?',
          description: 'Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for \'lorem ipsum\' will uncover many web sites still in their infancy.',
          active: false
        },
        {
          title: 'Distracted by the readable content of a page whent?',
          description: 'It has survived not only five centuries, but also the leap into electronic typesetting. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.',
          active: false
        },
        {
          title: 'What is user interface kit?',
          description: 'It has survived not only five centuries, but also the leap into electronic typesetting. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur.',
          active: false
        }
      ]
    }
  },
  mounted () {
    xray.index()
  }
}
</script>

<style lang="scss">
</style>
