<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header">
            <div class="row margin-bottom-3">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <div class="header__subtitle">Benefity webovej vizitky</div>
                <h1 class="alfa header__title h--gradient">Prečo sa <br> Zaregistrovať</h1>

              </div>

            </div>
            <div class="row gutter-md-60 gutter-lg-100">
              <div class="col-12 col-md-6 margin-bottom-3 d-none d-md-block">
                <div class="content-width-600 margin-center pos-r">
                  <img class="img--responsive" :src="require('../../assets/images/about/benefit-3.svg')" alt="" >
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="margin-bottom-2 p--margin-1 h--margin-1-2">
                  <h2 class="delta">Princíp fungovania je veľmi jednoduchý</h2>
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                </div>
                <div class="block__item margin-bottom-1 margin-bottom-sm-2 p--margin-0">
                  <div class="block__content">
                    <div class="block__icon-block box box--40 box--purple-01"><img class="img--responsive margin-center" :src="require('../../assets/images/icons/checked.svg')" alt="" width="25" ></div>
                    <div class="block__text-block">
                      <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ.</p>
                    </div>
                  </div>
                </div>
                <div class="block__item margin-bottom-1 margin-bottom-sm-2 p--margin-0">
                  <div class="block__content">
                    <div class="block__icon-block box box--40 box--purple-01"><img class="img--responsive margin-center" :src="require('../../assets/images/icons/checked.svg')" alt="" width="25" ></div>
                    <div class="block__text-block">
                      <p>Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek.</p>
                    </div>
                  </div>
                </div>
                <div class="block__item margin-bottom-1 margin-bottom-sm-2 p--margin-0">
                  <div class="block__content">
                    <div class="block__icon-block box box--40 box--purple-01"><img class="img--responsive margin-center" :src="require('../../assets/images/icons/checked.svg')" alt="" width="25" ></div>
                    <div class="block__text-block">
                      <p>Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a uskladníme.</p>
                    </div>
                  </div>
                </div>
                <div class="block__item p--margin-0">
                  <div class="block__content">
                    <div class="block__icon-block box box--40 box--purple-01"><img class="img--responsive margin-center" :src="require('../../assets/images/icons/checked.svg')" alt="" width="25" ></div>
                    <div class="block__text-block">
                      <p>Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="hr-line hr-line--purple-light-02 hr-line-5"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 h--margin-0 text-center margin-bottom-4 margin-bottom-md-4">
                <h2 class="gamma">Prečo práve my?</h2>
              </div>
            </div>
            <div class="row gutter-sm-50 icon--size-30 icon---purple-light p--margin-0 h--margin-05 p--size-14">
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Najširšia ponuka</div>
                    <p>Prekladáme do všetkých jazykov v Európe</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Rýchlosť</div>
                    <p>Jednoduché zadanie objednávky, najrýchlejšie dodanie prekladov na trhu</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Rodení prekladatelia</div>
                    <p>Bezchybnú komunikáciu možno dosiahnuť len s rodenými hovorcami</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Jednoduchosť</div>
                    <p>Preklady aj priamo v administrácii webstránky alebo e-shopu, či z Excel súboru</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Bezchybné preklady</div>
                    <p>Každý preklad je vždy kontrolovaný dvoma prekladateľmi</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Nacenenie</div>
                    <p>Nezáväzné nacenenie projektu</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Kvalita</div>
                    <p>Ručne vyberáme špecializovaných prekladateľov pre jednotlivé texty</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">Cena</div>
                    <p>Výborné ceny za preklady</p>
                  </div>
                </div>
              </div>
              <div class="block__item block__item--center margin-bottom-3 col-12 col-sm-6 col-md-4">
                <div class="block__content">
                  <div class="block__icon-block box box--40 box--purple-01">
                    <i class="ri-hospital-line ri-xl"></i>
                  </div>
                  <div class="block__text-block">
                    <div class="zeta">SEO expertíza</div>
                    <p>Preklady sú v súlade so optimalizáciou pre vyhľadávače v danej krajine</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center margin-top-3">
                <router-link to="auth/registracia">
                  <button class="btn btn-lg btn-primary">Zaregistrovať sa</button>
                </router-link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'

export default {
  name: 'About',
  data () {
    return {
      logo: logo

    }
  },
  mounted () {
    // xray.index()
  }
}
</script>

<style lang="scss">
@import "style";
</style>
