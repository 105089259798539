<template>
  <div class="iq-comingsoon pt-5">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-sm-9 text-center">
          <div class="iq-comingsoon-info">
            <div class="margin-bottom-4">
              <a href="#">
                <img :src="require('../../assets/images/logo.png')" class="img-fluid" alt="logo" width="200">
              </a>
            </div>
            <h2 class="mt-4 mb-1 margin-top-4">Na stránke sa pracuje</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { xray } from '../../config/pluginInit'

export default {
  name: 'ComingSoon',
  mounted () {
    xray.index()
  }
}
</script>
