<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header margin-bottom-6">
            <div class="row">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <div class="header__subtitle">Využite naše prehladné služby</div>
                <h1 class="alfa header__title h--gradient">Webová ordinácia lekára</h1>
                <div class="mb-5 header__content">
                  <p>je Vám k dispozícii 24 hodín denne 7 dní v týždni. Vo vzájomnej spolupráci s viacej než 1700 lekármi po celej Slovenskej republike pre Vás pravidelne aktualizujeme informácie o prevádzke v jednotlivých lekárskych ordináciách.</p>
                  <p>je Vám k dispozícii 24 hodín denne 7 dní v týždni. Vo vzájomnej spolupráci s viacej než 1700 lekármi po celej Slovenskej republike pre Vás pravidelne aktualizujeme informácie o prevádzke v jednotlivých lekárskych ordináciách.</p>
                </div>
                <router-link to="/preco-sa-registrovat">
                  <button type="button" class="btn btn-primary btn-lg margin-right-md-2">Prečo sa registrovať</button>
                </router-link>
                <!-- <button type="button" class="btn btn-primary btn-lg">Ukážkový profil</button> -->
              </div>
              <div class="col-12 col-md-4 position-static d-none d-md-block">
                <transition name="slide-fade">
                  <img :src="require('../../assets/images/intro/'+ heroImages[heroActiveImage] +'')"  alt="" class="img-fluid intro__img">
                </transition>
              </div>
            </div>
          </div>
          <div class="intro__content row justify-content-center">
            <div class="offer__item col-12 col-sm-12 col-md-4">
              <router-link to="/vyhladat-doktora">
                <div class="offer__content" @mouseover="changeHeroImage(0)">
                  <div class="offer__image-block">
                    <div class="offer__img">
                      <img :src="require('../../assets/images/intro/doctors.svg')" class="img-fluid" alt="logo">
                    </div>
                  </div>
                  <div class="offer__text-block">
                      <h2 class="offer__title">Vyhľadať lekára</h2>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="offer__item offer__item--3 col-12 col-sm-6 col-md-4">
              <router-link to="/auth/prihlasenie">
                <div class="offer__content" @mouseover="changeHeroImage(2)">
                  <div class="offer__image-block">
                    <div class="offer__img">
                      <img :src="require('../../assets/images/intro/storage.svg')" class="img-fluid" alt="logo">
                    </div>
                  </div>
                  <div class="offer__text-block">
                      <!-- <h2 class="offer__title">Prihlásenie & Registrácia</h2> -->
                      <h2 class="offer__title">Sklad</h2>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="offer__item col-12 col-sm-6 col-md-4">
              <router-link to="/dokumenty">
                <div class="offer__content" @mouseover="changeHeroImage(1)">
                  <div class="offer__image-block">
                    <div class="offer__img">
                      <img :src="require('../../assets/images/intro/documents.svg')" class="img-fluid" alt="logo">
                    </div>
                  </div>
                  <div class="offer__text-block">
                      <h2 class="offer__title">Dokumenty</h2>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'

export default {
  name: 'Intro',
  data () {
    return {
      logo: logo,
      heroActiveImage: 0,
      heroImages: ['doctors.svg', 'documents.svg', 'storage.svg']
    }
  },
  methods: {
    changeHeroImage (index) {
      this.heroActiveImage = index
    }
  },
  mounted () {
    // xray.index()
  }
}
</script>

<style lang="scss">
@import "style";
</style>
