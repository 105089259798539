<template>
  <!-- Footer -->
  <footer class="bg-white iq-footer txt-c txt-sm-l">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 margin-bottom-05 margin-bottom-sm-0">
          <ul class="list-inline mb-0">
            <slot name="left"></slot>
          </ul>
        </div>
        <div class="col-md-6 txt-c txt-sm-r">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer END -->
</template>
<script>
export default {
  name: 'FooterStyle1'
}
</script>

<style lang="scss" scoped>
.iq-footer--dashboard {
	flex-shrink: 0;
	@media (min-width: 1300px) {
		margin-bottom: 15px;
		margin-right: 15px;
		margin-left: 15px;
		border-radius: 25px;
	}
}

.iq-footer {
	@include font-size(14px);
}

.list-inline-item:not(:last-child) {
	margin-right: 1.5rem;
}
</style>
