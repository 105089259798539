<template>
  <b-container fluid>

    <b-row>
      <b-col lg="12">
        <iq-card body-class="p-0">
          <template v-slot:body>
            <div class="iq-edit-list">
              <tab-nav :pills="true" class="iq-edit-profile d-flex">
                <tab-nav-items class="col-md-3 p-0" :active="true" href="#personal-information" title="Osobné informácie" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#detail-information" title="Nástenka" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#employeer-information" title="Zamestnávateľ" />
                <tab-nav-items class="col-md-3 p-0" :active="false" href="#change-password" title="Zmeniť heslo" />
              </tab-nav>
            </div>
          </template>
        </iq-card>
      </b-col>
      <b-col lg="12">
        <div class="iq-edit-list-data">
          <tab-content id="pills-tabContent-2">
            <tab-content-item :active="true" id="personal-information" >
              <ValidationObserver v-slot="{ handleSubmit }">
                <form novalidate @submit.prevent="handleSubmit(updatePersonalForm)">
                  <b-row>
                    <b-col lg="3" class="txt-c">
                      <iq-card>
                        <template v-slot:headerTitle class="justify-content-center">
                          <h4 class="card-title">{{ user.firstname }} {{ user.lastname }}</h4>
                        </template>
                        <template v-slot:body>
                          <b-form-group class="row align-items-center justify-content-center">
                            <b-col md="12">
                              <div class="profile-img-edit">
                                <b-img :src="user.profile_image" class="profile-pic height-150 width-150" alt="profile-pic"/>
                                <input type="hidden" v-model="user.profile_image">
                                <div class="p-image">
                                  <div class="position-relative">
                                    <i class="ri-pencil-line upload-button"></i>
                                    <b-form-file class="h-100 position-absolute" accept="image/*" style="opacity: 0;" @change="previewImage"></b-form-file>
                                  </div>
                                </div>
                              </div>
                            </b-col>
                          </b-form-group>
                        </template>
                      </iq-card>
                    </b-col>
                    <b-col lg="9">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Typ používateľa</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <b-row>
                              <b-form-group class="col-12 col-sm-6 col-lg-4"
                                            label-for="typesOfUser"
                                            label="Typ osoby:">
                                <span class="select-form select-form-disabled">
                                  <b-form-select v-model="user.typeOfUser" size="lg" plain :options="typesOfUser" id="typesOfUser" disabled>
                                  </b-form-select>
                                </span>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-lg-4"
                                            label-for="typeOfMedic"
                                            label="Typ zdravotnika:" v-if="user.typeOfUser == 'Z'">
                                <span class="select-form">
                                  <b-form-select v-model="user.typeOfMedic" size="lg" plain :options="typesOfMedic" id="typesOfMedic" @change="changeProfileIcon">
                                  </b-form-select>
                                </span>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-lg-4" label="Špecializácia:" label-for="typeOfSpecialization" v-if="user.typeOfMedic === 'L'">
                                <ValidationProvider name="Špecializácia" v-slot="{ errors }">
                                  <span class="select-form">
                                    <b-form-select v-model="user.specialization" size="lg" plain :options="typesOfSpecialization" id="typeOfSpecialization" name="typeOfSpecialization" placeholder="Špecializácia">
                                    </b-form-select>
                                  </span>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                            </b-row>
                          </div>
                        </template>
                      </iq-card>

                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Kontaktné údaje</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <b-row>
                              <div class="col-12 col-sm-6">
                                <b-row class="gutter-10">
                                  <b-form-group class="col-4 col-sm-4" label="Titul:" label-for="degree">
                                    <ValidationProvider name="Titul" rules="required" v-slot="{ errors }">
                                      <b-form-input v-model="user.degree" type="text" name="degree" id="degree" placeholder="Titul" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                      <div class="invalid-feedback">
                                        <span>{{ errors[0] }}</span>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                  <b-form-group class="col-8 col-sm-8" label="Meno:" label-for="firstname">
                                    <ValidationProvider name="Meno" rules="required" v-slot="{ errors }">
                                      <b-form-input v-model="user.firstname" type="text" name="firstname" id="firstname" placeholder="Meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                      <div class="invalid-feedback">
                                        <span>{{ errors[0] }}</span>
                                      </div>
                                    </ValidationProvider>
                                  </b-form-group>
                                </b-row>
                              </div>
                              <b-form-group class="col-12 col-sm-6" label="Priezvisko:" label-for="lastname">
                                <ValidationProvider name="Priezvisko" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.lastname" type="text" name="lastname" id="lastname" placeholder="Priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                            </b-row>
                             <b-row>
                                <b-form-group class="col-12 col-sm-6"
                                              label="Ulica:"
                                              label-for="street"
                                >
                                  <ValidationProvider name="Ulica" v-slot="{ errors }">
                                    <b-form-input v-model="user.street" type="text" name="street" id="street" placeholder="Ulica"></b-form-input>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </b-form-group>
                                <b-form-group class="col-12 col-sm-6"
                                              label="PSČ:"
                                              label-for="psc"
                                >
                                  <ValidationProvider name="PSČ" v-slot="{ errors }">
                                    <b-form-input v-model="user.psc" type="text" name="psc" id="psc" placeholder="PSČ"></b-form-input>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </b-form-group>
                                <b-form-group class="col-12 col-sm-6"
                                              label="Mesto:"
                                              label-for="city"
                                >
                                  <ValidationProvider name="Mesto" v-slot="{ errors }">
                                    <b-form-input  v-model="user.city" type="text" name="city" id="city" placeholder="Mesto"></b-form-input>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </b-form-group>
                                <!-- <b-form-group class="col-12 col-sm-6"
                                              label="Názov spoločnosti:"
                                              label-for="cname"
                                >
                                  <b-form-input v-model="user.company_name" type="text" name="cname" id="cname" placeholder="Názov spoločnosti"></b-form-input>
                                </b-form-group> -->

                                <b-form-group class="col-12 col-sm-6" label="Telefónne číslo:" label-for="phone">
                                  <ValidationProvider name="Telefónne číslo" rules="required" v-slot="{ errors }">
                                    <b-form-input v-model="user.phone" type="text" name="phone" id="phone" placeholder="Telefónne číslo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </b-form-group>

                                <b-form-group class="col-12 col-sm-6" label="Pohlavie:" label-class="d-block">
                                  <b-form-radio inline v-model="user.gender" value="muž" name="gender" id="gender-male">Muž</b-form-radio>
                                  <b-form-radio inline v-model="user.gender" value="žena" name="gender" id="gender-female">Žena</b-form-radio>
                                </b-form-group>

                              </b-row>
                            <!-- <hr> -->
                          </div>
                        </template>
                      </iq-card>
                        <iq-card>
                          <template v-slot:headerTitle>
                            <h4 class="card-title">Prihlasovacie údaje</h4>
                          </template>
                          <template v-slot:body>
                            <b-row>
                              <b-form-group class="col-12 col-sm-6" label="Používatelské meno:" label-for="username">
                                <ValidationProvider name="Používatelské meno" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.username" type="text" name="username" id="username" disabled placeholder="Používatelské meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                              <b-form-group class="col-12 col-sm-6" label="Email:" label-for="email">
                                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                  <b-form-input v-model="user.email" type="text" name="email" id="email" placeholder="Email" disabled :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                            </b-row>
                          <!-- <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1 margin-bottom-2">
                            <input type="checkbox" class="custom-control-input" :id="formType">
                            <label class="custom-control-label" :for="formType">Akceptujem <router-link to="/pravidla-a-podmienky">pravidlá a podmienky</router-link></label>
                          </div> -->
                        </template>
                      </iq-card>
                      <div class="text-right margin-top-2 margin-bottom-4">
                        <b-button variant="primary" class="btn-lg" type="submit">Uložiť údaje</b-button>
                      </div>
                    </b-col>
                  </b-row>
                </form>
              </ValidationObserver>
            </tab-content-item>
            <tab-content-item :active="false" id="employeer-information" >
              <ValidationObserver v-slot="{ handleSubmit }">
                <form id="add-employee" novalidate @submit.prevent="handleSubmit(updateEmployeerForm)">
                  <b-row>
                    <b-col lg="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Obchodné údaje</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <b-row>
                              <b-form-group class="col-12 col-sm-6 col-md-4" label="IČO:" label-for="ico">
                                <ValidationProvider name="IČO" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.company.ico" type="text" name="ico" id="ico" placeholder="IČO" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                              <b-form-group class="col-12 col-sm-6 col-md-4" label="Obchodné meno:" label-for="company_name">
                                <ValidationProvider name="Obchodné meno" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.company.name" type="text" name="company_name" id="company_name" placeholder="Obchodné meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                            </b-row>

                            <b-row>
                              <b-form-group class="col-12 col-sm-6 col-md-4" label="Ulica:" label-for="street">
                                <ValidationProvider name="Ulica" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.company.street" type="text" name="street" id="street" placeholder="Ulica" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-md-4" label="PSČ:" label-for="psc">
                                <ValidationProvider name="PSČ" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.company.psc" type="text" name="psc" id="psc" placeholder="PSČ" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-md-4" label="Mesto:" label-for="city">
                                <ValidationProvider name="Mesto" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.company.city" type="text" name="city" id="city" placeholder="Mesto" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>
                            </b-row>
                            <b-row class="margin-top-2">
                              <b-form-group class="col-12 col-sm-6 col-md-4" label="Kód lekára:" label-for="personalCode">
                                <ValidationProvider name="Kód lekára" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.personalCode" type="text" name="personalCode" id="personalCode" placeholder="Kód lekára" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-md-4" label="Kód PZS:" label-for="insuranceCode">
                                <ValidationProvider name="Kód PZS" rules="required" v-slot="{ errors }">
                                  <b-form-input v-model="user.insuranceCode" type="text" name="insuranceCode" id="insuranceCode" placeholder="Kód PZS" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                            <b-form-group class="col-12 margin-top-1">
                              <b-form-checkbox v-model="user.employeesExist">Mám zamestnancov</b-form-checkbox>
                            </b-form-group>

                            </b-row>
                            <!-- <hr> -->
                          </div>
                        </template>
                      </iq-card>
                    </b-col>
                  </b-row>
                </form>
                <b-row>
                  <b-col lg="12">
                    <template v-if="user.employeesExist">
                      <iq-card class="box--blue">
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Vyhľadaj zamestnanca</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info margin-top-1">
                            <b-row class="gutter-20">
                              <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3" >
                                <ValidationProvider name="Meno" v-slot="{ errors }">
                                  <b-form-input v-model="filter.firstname" type="text" name="firstname" id="firstname" placeholder="Meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3" >
                                <ValidationProvider name="Priezvisko"  v-slot="{ errors }">
                                  <b-form-input v-model="filter.lastname" type="text" name="lastname" id="lastname" placeholder="Priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3">
                                <ValidationProvider name="Používateľské meno" v-slot="{ errors }">
                                  <b-form-input v-model="filter.username" type="text" name="username" id="username" placeholder="Používateľské meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </b-form-group>

                              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                                <b-button variant="primary" class="btn-lg btn-responsive btn-lg-ext" @click="filterEmplyoees()"><i class="ri-search-2-line margin-right-1"></i> Vyhľadať</b-button>
                              </div>

                            </b-row>
                            <!-- <hr> -->
                          </div>
                        </template>
                      </iq-card>

                      <iq-card v-if="rowsEmployees">
                        <template v-slot:headerTitle>
                          <!-- <h4 class="card-title">Vyhľadaj zamestnanca</h4> -->
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info margin-top-1">
                            <!-- :select-options="{
                              enabled: true ,
                              selectOnCheckboxOnly: true,
                              selectionText: 'Vybratých zamestnancov',
                              clearSelectionText: 'zmazať',
                            }" -->
                            <vue-good-table
                              :columns="columnsEmployees"
                              :rows="rowsEmployees"
                              @on-page-change="onPageChange"
                              :pagination-options="{
                                enabled: true,
                                mode: 'records',
                                perPage: 5,
                                position: 'bottom',
                                perPageDropdown: [10, 20, 30],
                                dropdownAllowAll: false,
                                nextLabel: 'ďalšia',
                                prevLabel: 'predchadzajúca',
                                rowsPerPageLabel: 'Výsledkov',
                                ofLabel: 'z',
                                pageLabel: 'stránka', // for 'pages' mode
                                allLabel: 'Všetky',
                              }"
                              >

                              <template slot="table-row" slot-scope="props" :class="{ 'is-selected': props.row.selected }">
                                <span v-if="props.column.field == 'authorizations'">
                                  <!-- <v-select name="user_id"  :options="typeOfAuthorizations"/> -->
                                  <ValidationProvider name="oprávnenie" rules="required" v-slot="{ errors }">
                                    <span class="select-form">
                                      <b-form-select v-model="props.row.authorizations" :id="'role_' + props.index" :name="'role_' + props.index" @change="selectedAuthorization($event, props.index)" placeholder="Vyberte oprávnenie" plain :options="typeOfAuthorizations" :class="(errors.length > 0 ? ' is-invalid' : '')">
                                      </b-form-select>
                                    </span>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </span>
                                <span v-else-if="props.column.field == 'dateFrom'">
                                  <flat-pickr class="form-control" v-model="props.row.dateFrom" @input="selectedDateFrom($event, props.index)" :config="basicConfigDatepicker" placeholder="Oprávnenie od"></flat-pickr>
                                </span>
                                <span v-else-if="props.column.field == 'action'">
                                  <div class="request__group">
                                    <div class="request__btn request__btn--filled-accept" v-if="!props.row.selected" @click="toggleEmployee(props.row, props.index)"><i class="ri-add-line"></i></div>
                                    <div class="request__btn request__btn--filled-decline" v-if="props.row.selected" @click="toggleEmployee(props.row, props.index)"><i class="ri-close-line"></i></div>
                                  </div>
                                </span>
                                <span v-else>
                                  {{props.formattedRow[props.column.field]}}
                                </span>
                              </template>

                              <!-- <template slot="column-filter" slot-scope="{ column, updateFilters }">
                                <my-custom-filter
                                  v-if="columns.filterOptions.customFilter"
                                  @input="(value) => updateFilters(column, value)"/>
                              </template> -->

                            </vue-good-table>
                          </div>
                        </template>
                      </iq-card>
                    </template>
                    <div class="text-right margin-top-2 margin-bottom-4">
                      <b-button form="add-employee" variant="primary" class="btn-lg" type="submit">Uložiť údaje</b-button>
                    </div>
                  </b-col>
                </b-row>
              </ValidationObserver>
            </tab-content-item>

            <tab-content-item :active="false" id="detail-information" >
              <ValidationObserver v-slot="{ handleSubmit }">
                <form id="wall-form" novalidate @submit.prevent="handleSubmit(updateProfileForm)">
                  <b-row>

                    <b-col lg="12">
                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">BIO</h4>
                        </template>
                        <template v-slot:body>
                          <b-form-group class="col-12" label="Uvodný popis:" label-for="description">
                            <ValidationProvider name="Uvodný popis" v-slot="{ errors }">
                              <b-form-textarea v-model="user.description" type="text" name="description" id="description" placeholder="Uvodný popis" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-textarea>
                              <div class="invalid-feedback">
                                <span>{{ errors[0] }}</span>
                              </div>
                            </ValidationProvider>
                          </b-form-group>
                        </template>
                      </iq-card>

                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Ordinačné hodiny</h4>
                        </template>
                        <template v-slot:body>
                          <div class="row">
                            <div class="col-12 margin-bottom-1">
                              <div class="d-flex align-items-center justify-content-end">
                                <b-button variant="primary" class="margin-right-1" @click="changeOrdinaryTable('add', 'column')"><i class="ri-add-line margin-right-1"></i> Stlpec</b-button>
                                <b-button variant="danger" class="" @click="changeOrdinaryTable('remove', 'column')">- Stlpec</b-button>
                              </div>
                            </div>
                          </div>
                          <div class="new-user-info">
                            <vue-good-table
                              :columns="columnsOrdinaryHours"
                              :rows="rowsOrdinaryHours"
                              >
                              <template slot="table-row" slot-scope="props">
                                <ValidationProvider v-slot="{ errors }">
                                  <b-form-input v-model="props.row[props.column.field]" @input="changeOrdinaryItem($event, props.index, props.column.field)" type="text" placeholder="Čas a úkon" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                  <div class="invalid-feedback">
                                    <span>{{ errors[0] }}</span>
                                  </div>
                                </ValidationProvider>
                              </template>

                            </vue-good-table>
                          </div>
                          <div class="row">
                            <div class="col-12 margin-bottom-1 margin-top-1">
                              <div class="txt-r">
                                <div class="margin-bottom-08">
                                  <b-button variant="primary" @click="changeOrdinaryTable('add', 'row')"><i class="ri-add-line margin-right-1"></i> Riadok</b-button>
                                </div>
                                <b-button variant="danger" class="" @click="changeOrdinaryTable('remove', 'row')">- Riadok</b-button>
                              </div>
                            </div>
                          </div>
                        </template>
                      </iq-card>

                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Aktuálne oznamy</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <vue-good-table
                              :columns="columnsNews"
                              :rows="rowsNews"
                              >
                              <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'dateFrom'">
                                  <flat-pickr class="form-control" v-model="props.row.dateFrom" @input="selectedDateFromNews($event, props.index)" :config="basicConfigDatepicker" placeholder="Oznam od"></flat-pickr>
                                </span>
                                <span v-else >
                                  <ValidationProvider v-slot="{ errors }">
                                    <b-form-input v-model="props.row[props.column.field]" @input="changeNewItem($event, props.index, props.column.field)" type="text" placeholder="Text" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                                    <div class="invalid-feedback">
                                      <span>{{ errors[0] }}</span>
                                    </div>
                                  </ValidationProvider>
                                </span>
                              </template>

                            </vue-good-table>
                          </div>
                          <div class="row">
                            <div class="col-12 margin-bottom-1 margin-top-1">
                              <div class="txt-r">
                                <div class="margin-bottom-08">
                                  <b-button variant="primary" @click="changeNewsTable('add', 'row')"><i class="ri-add-line margin-right-1"></i> Riadok</b-button>
                                </div>
                                <b-button variant="danger" class="" @click="changeNewsTable('remove', 'row')">- Riadok</b-button>
                              </div>
                            </div>
                          </div>
                        </template>
                      </iq-card>

                      <iq-card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">Služby</h4>
                        </template>
                        <template v-slot:body>
                          <div class="new-user-info">
                            <div class="row margin-bottom-1">
                              <div class="col-9">
                                <b-form-input v-model="serviceNew" type="text" placeholder="Text"></b-form-input>
                              </div>
                              <div class="col-3">
                                <b-button variant="primary" class="btn-lg-ext btn-responsive" @click="addNewService(serviceNew)"><i class="ri-add-line margin-right-1"></i> Pridať službu</b-button>
                              </div>
                            </div>
                            <div class="btn-service-group">
                              <span class="btn btn-outline-primary btn--service margin-bottom-08 margin-right-08" v-for="service in servicesList" :key="service.id">
                                {{ service.title }}
                                <span><i class="ri-close-line" @click="removeService(service.id)"></i></span>
                              </span>
                            </div>
                          </div>
                        </template>
                      </iq-card>

                    </b-col>
                  </b-row>
                </form>
                <div class="text-right margin-bottom-4">
                  <b-button variant="primary" form="wall-form" class="btn-lg" type="submit">Uložiť údaje</b-button>
                </div>
              </ValidationObserver>
            </tab-content-item>

            <tab-content-item :active="false" id="change-password">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form novalidate @submit.prevent="handleSubmit(updatePasswordForm)">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Zmeniť heslo</h4>
                    </template>
                    <template v-slot:body>
                      <b-form-group label="Nové heslo:" label-for="pass">
                        <ValidationProvider name="Nové heslo" rules="confirmed:repeat_password" v-slot="{ errors }">
                          <b-form-input v-model="user.password" type="password" placeholder="Nové heslo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <b-form-group label="Zopakuj heslo:" label-for="rpass">
                        <ValidationProvider vid="repeat_password" name="Zopakuj heslo" rules="required" v-slot="{ errors }">
                          <b-form-input v-model="user.repeat_password" type="password" placeholder="Zopakuj heslo" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </b-form-group>
                      <div class="margin-top-1-5">
                        <b-button type="submit" variant="primary" class="mr-2 btn-lg">Zmeniť heslo</b-button>
                        <!-- <b-button type="reset" variant="none" class="iq-bg-danger btn-lg">Cancel</b-button> -->
                      </div>
                    </template>
                  </iq-card>
                </form>
              </ValidationObserver>
            </tab-content-item>
          </tab-content>
        </div>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'

export default {
  name: 'ProfileEdit',
  components: {
    VueGoodTable
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      user: {
        degree: 'Mudr.',
        firstname: 'Jana',
        lastname: 'Kováčová',
        username: 'JanaKovacova',
        email: 'jakubtursky769@gmail.com',
        password: '123456789',
        description: '',
        street: 'Ladislava Majerského 580',
        psc: '96605',
        city: 'Hliník nad Hronom',
        gender: 'male',
        profile_image: require('../../assets/images/user/08.jpg'),
        phone: '0456 123 456',
        typeOfUser: 'Z',
        typeOfMedic: 'L',
        specialization: 'kardiochirurg',
        employer: false,
        personalCode: '456',
        insuranceCode: '456456',
        employeesExist: true,
        employees: [],
        social: {
          facebook_url: 'facebook/jakubtursky',
          twitter_url: '',
          linkedin_url: '',
          instagram_url: ''
        },
        company: {
          name: '123456',
          ico: 'Obchodne medno',
          street: 'l. štura',
          psc: '320',
          city: 'Hliník nad hronom'
        }
      },
      typesOfUser: [
        { id: 0, value: 'Z', text: 'Z - zdravotnik' },
        { id: 1, value: 'O', text: 'O - osoba, pacient' }
      ],
      typesOfMedic: [
        { id: 0, value: 'L', text: 'L - lekár' },
        { id: 1, value: 'S', text: 'S - zdravotná sestra' },
        { id: 2, value: 'I', text: 'I - iný zdravotnicky pracovník' }
      ],
      typesOfSpecialization: [
        { id: 0, value: 'kardiochirurg', text: 'Kardiochirurg' },
        { id: 1, value: 'neurolog', text: 'Neurológ' },
        { id: 2, value: 'kožný', text: 'Kožný' }
      ],
      filter: {
        firstname: '',
        lastname: '',
        username: ''
      },
      typeOfAuthorizations: ['Sklad', 'Dotaznik', 'Iné'],
      basicConfigDatepicker: {
        dateFormat: 'm-d-Y'
      },
      columnsOrdinaryHours: [
        { label: 'Deň', field: 'day', width: '200px' },
        { label: 'Čas & úkon', field: 'time_1', width: '200px' },
        { label: 'Čas & úkon', field: 'time_2', width: '200px' },
        { label: 'Čas & úkon', field: 'time_3', width: '200px' }
      ],
      rowsOrdinaryHours: [
        { id: 1, day: 'Pondelok', time_1: '8:00 - 12:00 - ukon', time_2: '12:00 - 12:30 - prestávka', time_3: '12:30 - 15:00 - administrativa' },
        { id: 2, day: 'Utorok', time_1: '8:00 - 12:00 - ukon', time_2: '12:00 - 12:30 - prestávka', time_3: '12:30 - 15:00 - administrativa' },
        { id: 3, day: 'Streda', time_1: '8:00 - 12:00 - ukon', time_2: '12:00 - 12:30 - prestávka', time_3: '12:30 - 15:00 - administrativa' },
        { id: 4, day: 'Štvrtok', time_1: '8:00 - 12:00 - ukon', time_2: '12:00 - 12:30 - prestávka', time_3: '12:30 - 15:00 - administrativa' },
        { id: 5, day: 'Piatok', time_1: '8:00 - 12:00 - ukon', time_2: '12:00 - 12:30 - prestávka', time_3: '12:30 - 15:00 - administrativa' },
        { id: 6, day: 'Sobota', time_1: '8:00 - 12:00 - ukon', time_2: '---', time_3: '---' }
      ],

      columnsNews: [
        { label: 'Nadpis', field: 'title', width: '180px' },
        { label: 'Popis', field: 'text', width: '260px' },
        { label: 'Dátum platnosti od', field: 'dateFrom', width: '200px' }
      ],
      rowsNews: [
        { id: 1, title: '', text: '', dateFrom: this.setToToday() },
        { id: 2, title: '', text: '', dateFrom: this.setToToday() },
        { id: 3, title: '', text: '', dateFrom: this.setToToday() }
      ],
      serviceNew: '',
      servicesList: [
        { id: 0, title: 'Názov služby č.1' },
        { id: 1, title: 'Názov služby č.2' },
        { id: 2, title: 'Názov služby č.3' }
      ],
      columnsEmployees: [
        { label: 'Meno', field: 'firstname' },
        { label: 'Priezvisko', field: 'lastname' },
        { label: 'Používatelské meno', field: 'username' },
        { label: 'Oprávnenia', field: 'authorizations', width: '200px' },
        { label: 'Dátum od', field: 'dateFrom', width: '200px' },
        { label: 'Akcia', field: 'action', width: '120px' }
      ],
      rowsEmployees: [
        { id: 1, selected: true, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: 'Sklad', dateFrom: '01-26-2021' },
        { id: 2, selected: true, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: 'Dotaznik', dateFrom: '02-30-2021' }
      ]
    }
  },
  methods: {
    setToToday: function () {
      let today = moment().format('MM-DD-YYYY')
      return today
    },
    selectedAuthorization: function (value, index) {
      this.rows[index].authorizations = value
    },
    selectedDateFrom: function (value, index) {
      this.rows[index].dateFrom = value
    },
    selectedDateFromNews: function (value, index) {
      this.rowsNews[index].dateFrom = value
    },
    changeNewItem: function (value, index, column) {
      this.rowsNews[index][column] = value
    },
    changeOrdinaryItem: function (value, index, column) {
      this.rowsOrdinaryHours[index][column] = value
    },
    addNewService: function (value) {
      if (value.length > 0) {
        this.servicesList.push({
          'id': this.servicesList[this.servicesList.length - 1].id + 1,
          'title': value
        })
        this.serviceNew = ''
      }
    },
    removeService: function (index) {
      this.servicesList.splice(index, 1)
    },
    changeOrdinaryTable: function (action, type) {
      if (action === 'add') {
        if (type === 'column') {
          let columnLength = this.columnsOrdinaryHours.length
          this.columnsOrdinaryHours.push({ label: 'Čas & úkon', field: `time_${columnLength}` })
          for (let i = 0; i < this.rowsOrdinaryHours.length; i++) {
            this.rowsOrdinaryHours[i][`time_${columnLength}`] = '---'
          }
        }
        if (type === 'row') {
          let newRow = {
            id: this.rowsOrdinaryHours.length + 1,
            day: '---'
          }
          for (let i = 2; i < Object.keys(this.rowsOrdinaryHours[0]).length; i++) {
            newRow[`time_${i - 1}`] = '---'
          }
          this.rowsOrdinaryHours.push(newRow)
        }
      }
      if (action === 'remove') {
        if (type === 'column') {
          let columnLength = this.columnsOrdinaryHours.length - 1
          this.columnsOrdinaryHours.splice(columnLength, 1)
        }
        if (type === 'row') {
          let rowLength = this.rowsOrdinaryHours.length - 1
          this.rowsOrdinaryHours.splice(rowLength, 1)
        }
      }
    },
    changeNewsTable: function (action, type) {
      if (action === 'add') {
        if (type === 'row') {
          let newRow = { id: this.rowsNews.length + 1, title: '', text: '', dateFrom: this.setToToday() }
          this.rowsNews.push(newRow)
        }
      }
      if (action === 'remove') {
        if (type === 'row') {
          let rowLength = this.rowsNews.length - 1
          this.rowsNews.splice(rowLength, 1)
        }
      }
    },
    toggleEmployee: function (person, index) {
      this.rows[index].selected = !this.rows[index].selected
      // let nthIndex = index + 1
      // document.querySelector('#vgt-table tbody tr:nth-child(' + nthIndex + ')').classList.toggle('is-selected')

      // add row to user object with selected Employees
      this.user.employees = this.rowsEmployees.filter(item => item.selected === true)
    },
    onPageChange: function (params) {
      // setTimeout(() => {
      //   window.jQuery('#vgt-table tbody tr .request__btn--filled-decline').each(function () {
      //     window.jQuery(this).closest('tr').addClass('is-selected')
      //   })
      // }, 100)
    },
    filterEmplyoees () {
      if (
        this.filter.firstname.length > 0 ||
        this.filter.lastname.length > 0 ||
        this.filter.username.length > 0
      ) {
        console.log(this.filter)
      }
    },
    changeProfileIcon: function () {
      if (this.user.type === 'O - osoba, pacient') {
        this.user.profile_image = require('../../assets/images/user/14.jpg')
      } else {
        if (this.user.typeOfMedic === 'L - lekár') {
          this.user.profile_image = require('../../assets/images/user/08.jpg')
        } else if (this.user.typeOfMedic === 'S - zdravotná sestra') {
          this.user.profile_image = require('../../assets/images/user/07.jpg')
        } else if (this.user.typeOfMedic === 'I - iný zdravotnicky pracovník') {
          this.user.profile_image = require('../../assets/images/user/09.jpg')
        } else {
          this.user.profile_image = require('../../assets/images/user/08.jpg')
        }
      }
    },
    previewImage: function (event) {
      const input = event.target

      if (input.files && input.files[0]) {
        const reader = new FileReader()

        reader.onload = (e) => {
          this.user.profile_image = e.target.result
        }

        reader.readAsDataURL(input.files[0])
      }
    },
    changePassword: function () {
    },
    updatePersonalForm () {
      console.log(this.user)
      return false
    },
    updateProfileForm () {
      // Bio
      console.log(this.user.description)

      // Ordinary hours
      console.log(this.rowsOrdinaryHours)

      // News
      console.log(this.rowsNews)

      // Services
      console.log(this.servicesList)
      return false
    },
    updateEmployeerForm () {
      console.log(this.user)
      console.log(this.rowsEmployees)
      return false
    },
    updatePasswordForm () {
      console.log(this.user.password)
      return false
    }
  }
}
</script>
