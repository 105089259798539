<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form id="filter-storage" ref="filterStorageTable" novalidate @submit.prevent="handleSubmit(filterStorageTable)">
        <iq-card class="box--blue">
          <template v-slot:headerTitle>
            <h4 class="card-title">Zoznam skladových kariet</h4>
          </template>
          <template v-slot:body>
            <div class="new-user-info margin-top-1">
              <b-row class="gutter-20">
                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="typesOfStorageCard"
                              label="Typ skladovej karty:">
                  <ValidationProvider name="role" v-slot="{ errors }">
                    <span class="select-form">
                      <b-form-select v-model="filter.type" size="lg" plain :options="typesOfStorageCard" id="typesOfStorageCard" name="typesOfStorageCard" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                    </span>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="typesOfOperation"
                              label="Operácie:">
                  <ValidationProvider name="role" v-slot="{ errors }">
                    <span class="select-form">
                      <b-form-select v-model="filter.operation" size="lg" plain :options="typesOfOperation" id="typesOfOperation" name="typesOfOperation" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                    </span>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="dateFrom"
                              label="Dátum od:">
                  <ValidationProvider name="role" v-slot="{ errors }">
                    <flat-pickr class="form-control" v-model="filter.dateFrom"  name="dateFrom" id="dateFrom" :config="basicConfigDatepicker" placeholder="Dátum od"></flat-pickr>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="dateTo"
                              label="Dátum do:">
                  <ValidationProvider name="role" v-slot="{ errors }">
                    <flat-pickr class="form-control" v-model="filter.dateTo"  name="dateTo" id="dateTo" :config="basicConfigDatepicker" placeholder="Dátum do"></flat-pickr>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <!-- <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="numberOfStorageCard"
                              label="Číslo skladovej karty:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors }">
                    <b-form-select v-model="storage.number" size="lg" plain :options="numberOfStorageCard" id="numberOfStorageCard" name="numberOfStorageCard" :class="(errors.length > 0 ? ' is-invalid' : '')">
                    </b-form-select>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group> -->

                <div class="col-12 txt-r">
                  <b-button variant="primary" type="submit" class="btn-lg btn-lg-ext"><i class="ri-search-2-line margin-right-1"></i> Vyhľadať</b-button>
                </div>

              </b-row>
              <!-- <hr> -->
            </div>
          </template>
        </iq-card>
      </form>
    </ValidationObserver>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <!-- <h4 class="card-title">Vyhľadaj zamestnanca</h4> -->
          </template>
          <template v-slot:body>
            <div class="txt-r">
              <b-button variant="primary" v-b-modal.modal-add-new-card class="btn-lg-ext"><i class="ri-file-text-line margin-right-1"></i> Pridať novú</b-button>
            </div>
            <div class="new-user-info margin-top-1">
              <!-- :select-options="{
                enabled: true ,
                selectOnCheckboxOnly: true,
                selectionText: 'Vybratých zamestnancov',
                clearSelectionText: 'zmazať',
              }" -->
              <vue-good-table
                :columns="columnsStorageCards"
                :rows="rowsStorageCards"
                @on-page-change="onPageChange"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 5,
                  position: 'bottom',
                  perPageDropdown: [10, 20, 30],
                  dropdownAllowAll: false,
                  nextLabel: 'ďalšia',
                  prevLabel: 'predchadzajúca',
                  rowsPerPageLabel: 'Výsledkov',
                  ofLabel: 'z',
                  pageLabel: 'stránka', // for 'pages' mode
                  allLabel: 'Všetky',
                }"
                >

                <template slot="table-row" slot-scope="props" :class="{ 'is-selected': props.row.selected }">
                  <span v-if="props.column.field == 'action'">
                    <div class="request__group">
                      <div class="request__btn request__btn--filled-accept" v-b-modal.modal-show-card v-if="!props.row.selected" @click="showCard(props.formattedRow, props.index)"><i class="ri-eye-line"></i></div>
                    </div>
                  </span>
                  <span v-else-if="props.column.field == 'note'">
                    <span v-if="props.row.note.title === 'Ano'">
                      <span class="d-inline-block" tabindex="0" v-b-popover.hover.top :title="props.row.note.description">{{props.row.note.title}}</span>
                    </span>
                    <span v-else>{{props.row.note.title}}</span>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>

              </vue-good-table>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>

    <!-- MODAL ADD CARD -->
    <ValidationObserver ref="add-storage-card-observer" v-slot="{ passes }">
      <b-modal
        id="modal-add-new-card"
        size="xl"
        ref="modal-add-new-card"
        title="Pridať novú skladovu kartu"
        ok-title="Uložiť skladovú kartu"
        cancel-title="Zrušiť"
        header-bg-variant="primary"
        @show="resetModal"
        @hidden="resetModal"
        @ok.prevent="passes(addNewStorageTable)"
      >
        <!-- <form id="formAddNewCard" novalidate @submit.prevent="handleSubmit(addNewStorageTable)"> -->
          <div class="new-user-info margin-top-1">
              <b-row class="gutter-20">
                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_type"
                              label="Typ položky:">
                  <ValidationProvider name="role" v-slot="{ errors, validationContext }">
                    <span class="select-form">
                      <b-form-select v-model="newCard.type" size="lg" plain :options="typesOfOperation" id="newCard_type" name="newCard_type" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                    </span>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </b-row>

              <b-row class="gutter-20">
                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_typesOfOperation"
                              label="Operácie:">
                  <ValidationProvider name="role" v-slot="{ errors, validationContext }">
                    <span class="select-form">
                      <b-form-select v-model="newCard.operation" size="lg" plain :options="typesOfOperation" id="newCard_typesOfOperation" name="newCard_typesOfOperation" :class="(errors.length > 0 ? ' is-invalid' : '')">
                      </b-form-select>
                    </span>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <!-- <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_numberOfStorageCard"
                              label="Číslo skladovej karty:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors }">
                    <b-form-input v-model="newCard.number" size="lg" type="text" name="newCard_numberOfStorageCard" id="newCard_numberOfStorageCard" placeholder="Číslo skladovej karty:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group> -->

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_created"
                              label="Dátum založenia:">
                  <ValidationProvider name="role" v-slot="{ errors, validationContext }">
                    <flat-pickr class="form-control" v-model="newCard.dateCreated"  name="newCard_created" id="newCard_created" :config="basicConfigDatepicker" placeholder="Dátum založenia"></flat-pickr>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

              </b-row>

              <b-row class="gutter-20">
                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_code"
                              label="Číslo skladovej karty:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-input v-model="newCard.code" size="lg" type="text" name="newCard_code" id="newCard_code" placeholder="Číslo skladovej karty:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_name"
                              label="Názov:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-input v-model="newCard.name" size="lg" type="text" name="newCard_name" id="newCard_name" placeholder="Názov:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_addons"
                              label="Doplnok:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-input v-model="newCard.addons" size="lg" type="text" name="newCard_addons" id="newCard_addons" placeholder="Doplnok:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3"
                              label-for="newCard_group"
                              label="Indikačná skupina:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors }">
                    <b-form-input v-model="newCard.group" size="lg" type="text" name="newCard_group" id="newCard_group" placeholder="Indikačná skupina:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

              </b-row>

              <b-row class="gutter-20">

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-4"
                              label-for="dateOfExpiration"
                              label="Dátum expirácie:">
                  <ValidationProvider name="role" v-slot="{ errors, validationContext }">
                    <flat-pickr class="form-control" v-model="newCard.dateOfExpiration" name="dateOfExpiration" id="dateOfExpiration" :config="monthConfigDatepicker"  placeholder="Dátum expirácie"></flat-pickr>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-4"
                              label-for="newCard_amount"
                              label="Počet ks:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-input v-model="newCard.amount" size="lg" type="text" name="newCard_amount" id="newCard_amount" placeholder="Počet ks:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-4"
                              label-for="newCard_amount_actual"
                              label="Aktuálny počet:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-input v-model="newCard.amount_actual" size="lg" type="text" name="newCard_amount_actual" id="newCard_amount_actual" placeholder="Aktuálny počet:" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group class="col-12"
                              label-for="newCard_note"
                              label="Poznámka:">
                  <ValidationProvider name="role" rules="required" v-slot="{ errors, validationContext }">
                    <b-form-textarea v-model="newCard.note" type="text" name="newCard_note" id="newCard_note" placeholder="Poznámka" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-textarea>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>

              </b-row>
            </div>
        <!-- </form> -->
      <!-- <template #modal-footer="{ ok, cancel }">
        <button type="button" class="btn btn-secondary" @click="cancel()">Zrušiť</button>
        <b-button type="submit" variant="primary" form="formAddNewCard" class="btn btn-primary">Uložiť skladovú kartu</b-button>
      </template> -->
      </b-modal>
    </ValidationObserver>

      <!-- MODAL SHOW CARD -->
    <b-modal
      id="modal-show-card"
      size="md"
      ref="modal-show-card"
      title="Skladová karta"
      cancel-title="Zatvoriť"
      header-bg-variant="primary"
    >
      <div class="row margin-bottom-05" v-if="showCardRow.numberOfCard">
        <div class="col-12 col-sm-4 txt-600">Číslo karty:</div>
        <div class="col-12 col-sm-8">{{showCardRow.numberOfCard}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.codeOfCard">
        <div class="col-12 col-sm-4 txt-600">Kód:</div>
        <div class="col-12 col-sm-8">{{showCardRow.codeOfCard}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.expiration">
        <div class="col-12 col-sm-4 txt-600">Expirácia:</div>
        <div class="col-12 col-sm-8">{{showCardRow.expiration}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.operation">
        <div class="col-12 col-sm-4 txt-600">Operácia:</div>
        <div class="col-12 col-sm-8">{{showCardRow.operation}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.date">
        <div class="col-12 col-sm-4 txt-600">Dátum, čas:</div>
        <div class="col-12 col-sm-8">{{showCardRow.date}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.amount">
        <div class="col-12 col-sm-4 txt-600">Počet ks:</div>
        <div class="col-12 col-sm-8">{{showCardRow.amount}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.amount_actual">
        <div class="col-12 col-sm-4 txt-600">Aktuálny počet:</div>
        <div class="col-12 col-sm-8">{{showCardRow.amount_actual}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.update">
        <div class="col-12 col-sm-4 txt-600">Aktualizoval:</div>
        <div class="col-12 col-sm-8">{{showCardRow.update}}</div>
      </div>

      <div class="row margin-bottom-05" v-if="showCardRow.note">
        <div class="col-12 col-sm-4 txt-600">Poznámka:</div>
        <div class="col-12 col-sm-8">{{showCardRow.note.description}}</div>
      </div>

    </b-modal>

  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

export default {
  name: 'StorageTable',
  components: {
    VueGoodTable
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      filter: {
        type: '',
        operation: '',
        dateFrom: '',
        dateTo: ''
      },
      newCard: {
        type: '',
        operation: 'P',
        dateCreated: '',
        number: '',
        code: '',
        name: '',
        addons: '',
        group: '',
        dateOfExpiration: '',
        amount: '',
        amount_actual: '',
        note: ''
      },
      typesOfStorageCard: [
        { value: 'L', text: 'L - lieky' },
        { value: 'Z', text: 'O - zdr. pomôcka' }
      ],
      typesOfOperation: [
        { value: 'P', text: 'P - príjem' },
        { value: 'V', text: 'V - výdaj, použitie' },
        { value: 'K', text: 'K - kontrola' },
        { value: 'R', text: 'R - vrátenie' }
      ],
      basicConfigDatepicker: {
        dateFormat: 'd-m-Y'
      },
      monthConfigDatepicker: {
        plugins: [
          /*eslint-disable */
          new monthSelectPlugin({
            shorthand: true,
            dateFormat: 'm-Y'
          })
          /* eslint-enable */
        ]
      },
      showCardRow: {},
      columnsStorageCards: [
        { label: 'Čís. karty', field: 'numberOfCard' },
        { label: 'Kód', field: 'codeOfCard' },
        { label: 'Doplnok', field: 'addons' },
        { label: 'Exspirácia', field: 'expiration' },
        { label: 'Operácia', field: 'operation' },
        { label: 'Dátum, čas', field: 'date' },
        { label: 'Počet ks', field: 'amount' },
        { label: 'Ak. počet', field: 'amount_actual' },
        { label: 'Aktualizoval', field: 'update' },
        { label: 'Poznámka', field: 'note' },
        { label: 'Akcia', field: 'action', width: '120px' }
      ],
      rowsStorageCards: [
        {
          id: 1,
          selected: false,
          numberOfCard: 'L-001-5844B',
          codeOfCard: 'Noradrenalin',
          addons: 'con inf 2x2 ml/5 mg(amp. skl.)',
          expiration: '2-2021',
          operation: 'P',
          date: '7.1.2021 13:15',
          amount: '10',
          amount_actual: '10',
          update: 'Turská',
          note: {
            title: 'Ano',
            description: 'Toto je text poznámky Toto jky Toto je text poznámky Toto jky Toto je text poznámky Toto jky Toto je text poznámky Toto jky Toto je text poznámky Toto jky'
          }
        },
        {
          id: 2,
          selected: false,
          numberOfCard: 'L-001-5844B',
          codeOfCard: 'Noradrenalin',
          addons: 'con inf 2x2 ml/5 mg(amp. skl.)',
          expiration: '2-2021',
          operation: 'P',
          date: '7.1.2021 13:15',
          amount: '10',
          amount_actual: '10',
          update: 'Turská',
          note: {
            title: 'Nie',
            description: ''
          }
        },
        {
          id: 3,
          selected: false,
          numberOfCard: 'L-001-5844B',
          codeOfCard: 'Noradrenalin',
          addons: 'con inf 2x2 ml/5 mg(amp. skl.)',
          expiration: '2-2021',
          operation: 'P',
          date: '7.1.2021 13:15',
          amount: '10',
          amount_actual: '10',
          update: 'Turská',
          note: {
            title: 'Ano',
            description: 'Toto je text poznámky'
          }
        }
      ]
    }
  },
  computed: {
    fullName: function () {
      return this.user.firstname + ' ' + this.user.lastname
    }
  },
  methods: {
    showCard: function (card, index) {
      this.showCardRow = card
    },
    onPageChange: function (params) {
      setTimeout(() => {
        window.jQuery('#vgt-table tbody tr .request__btn--filled-decline').each(function () {
          window.jQuery(this).closest('tr').addClass('is-selected')
        })
      }, 100)
    },
    filterStorageTable () {
      if (
        this.filter.type.length > 0 ||
        this.filter.operation.length > 0 ||
        this.filter.dateFrom.length > 0 ||
        this.filter.dateTo.length > 0
      ) {
        console.log(this.filter)
      }
    },

    resetModal () {
      this.rateListNew = []
    },
    // handleOk (bvModalEvt) {
    //   // Prevent modal from closing
    //   bvModalEvt.preventDefault()
    //   // Trigger submit handler
    //   this.addNewStorageTable()
    // },
    getValidationState ({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    addNewStorageTable () {
      console.log(this.newCard)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal')
      })
    }
  }
}
</script>

<style lang="scss">
.profile-img-edit {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.iq-bg-primary {
	cursor: pointer;
	&:hover {
		background: darken(#ceebee, 10%) !important;
	}
}

table.vgt-table {
	font-size: 14px;
}

.vgt-table thead th {
	padding: 1.2em 1.8em 1.2em 0.75em;
	vertical-align: middle;
	line-height: 1.4;
}

.vgt-wrap__footer .footer__row-count__label {
	font-size: 14px;
	margin-bottom: 0;
}

.vgt-wrap__footer .footer__navigation__page-btn span,
.vgt-wrap__footer .footer__navigation {
	font-size: 14px;
}

.vgt-wrap__footer .footer__row-count__select {
	font-size: 14px;
	border: 1px solid #d7dbda;
	border-radius: 10px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
	border-right-color: $color-green;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
	border-left-color: $color-green;
}

table.vgt-table td {
	vertical-align: middle;
}

table.vgt-table tr.is-selected {
	background: #eff7f8;
}

.vgt-table thead th:last-child {
	text-align: center;
}

.vgt-wrap__footer {
	border-top: 0;
	background: white;
}

.vgt-table th.vgt-checkbox-col {
	vertical-align: middle;
	input {
		width: 20px;
		height: 20px;
	}
}

.vgt-table th.vgt-checkbox-col,
.vgt-table th.line-numbers,
.vgt-table thead th {
	background: #ecf4ff;
	border: 1px solid #bcd1f1;
}

.vgt-table.bordered thead th {
	background: #ecf4ff;
	border: 1px solid #bcd1f1;
}

.request__group {
	display: flex;
	align-self: center;
	justify-content: center;
}

.request__btn {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	// padding-left: 10px;
	// padding-right: 10px;
	width: 40px;
	height: 40px;
	cursor: pointer;
	font-weight: 500;
}

.request__btn--filled-accept {
	background: rgba(87, 173, 0, 0.1);
	color: #57ad00;
	&:hover {
		background: rgba(87, 173, 0, 0.3);
	}
	i {
		font-size: 24px;
		color: #57ad00;
	}
}

.request__btn--filled-decline {
	background: rgba(255, 65, 65, 0.2);
	color: #ff4141;
	&:hover {
		background: rgba(255, 65, 65, 0.4);
	}
	i {
		font-size: 24px;
		color: #ff4141;
	}
}

.popover {
	background: transparent;
	border: 0;
	.popover-header {
		background: #ecf4ff;
		border: 1px solid #bcd1f1;
		@include font-size(14px);
	}

	&.bs-popover-auto[x-placement^="bottom"] > .arrow::after,
	&.bs-popover-bottom > .arrow::after {
		border-bottom-color: #ecf4ff;
	}

	&.bs-popover-auto[x-placement^="bottom"] > .arrow::before,
	&.bs-popover-bottom > .arrow::before {
		border-bottom-color: #bcd1f1;
	}
	&.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
	&.bs-popover-bottom .popover-header::before {
		border-bottom-color: #ecf4ff;
	}

	&.bs-popover-auto[x-placement^="top"] > .arrow::after,
	&.bs-popover-top > .arrow::after {
		border-top-color: #ecf4ff;
	}

	&.bs-popover-auto[x-placement^="top"] > .arrow::before,
	&.bs-popover-top > .arrow::before {
		border-top-color: #bcd1f1;
	}
	&.bs-popover-auto[x-placement^="top"] .popover-header::before,
	&.bs-popover-top .popover-header::before {
		border-top-color: #ecf4ff;
	}
}
</style>
