<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header margin-bottom-6">
            <div class="row">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <div class="header__subtitle">Vyberte si z ponuky dokumentov</div>
                <h1 class="alfa header__title h--gradient">Dokumenty <br> na stiahnutie</h1>
                <div class="mb-5 header__content">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi, earum sunt! Excepturi nisi voluptatibus assumenda doloribus doloremque. Laboriosam quod iste ratione aperiam culpa, cupiditate neque deserunt inventore, exercitationem, veritatis laudantium!</p>
                </div>
                <!-- <button type="button" class="btn btn-primary btn-lg">Pozrieť si ponuku</button> -->
              </div>
              <div class="col-12 col-md-4 position-static d-none d-md-block">
                <transition name="slide-fade">
                  <img :src="require('../../assets/images/intro/doctors.svg')"  alt="" class="img-fluid intro__img">
                </transition>
              </div>
            </div>
          </div>

          <div class="content-width-sm-600">
            <div class="delta h--gradient">Kategoria dokumentov v1</div>
            <b-list-group class="list-group-ext h--margin-0 h--font-medium">
              <b-list-group-item href="#" v-for="item in documents" :key="item.id" class="margin-bottom-08">
                <h2 class="zeta list-group-item__title"> <i class="ri-file-download-line margin-right-1"></i> {{ item.title }}</h2>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div class="margin-top-5">
            <div class="content-width-sm-600">
              <div class="delta h--gradient">Kategoria dokumentov v2</div>
              <b-list-group class="list-group-ext h--margin-0 h--font-bold">
                <b-list-group-item href="#" v-for="item in documents" :key="item.id" class="list-group-item--box flex-column margin-bottom-08 align-items-start">
                  <div class="d-flex w-100 justify-content-between">
                    <h2 class="mb-1 zeta list-group-item__title">{{ item.title }}</h2>
                  </div>
                  <p class="mb-4 list-group-item__text"> {{ item.description }} </p>
                  <button class="btn btn-primary btn-lg">Stiahnuť</button>
                </b-list-group-item>
              </b-list-group>
            </div>
          </div>

          <div class="margin-top-5">
            <div class="delta h--gradient">Kategoria dokumentov v3</div>
            <div class="row list-group-ext h--margin-0 h--font-bold">
              <div class="col-12 col-sm-6 col-md-4" v-for="item in documents" :key="item.id">
                <b-card
                  :title="item.title"
                  class="iq-mb-3"
                  :header="item.category"
                >
                  <b-card-text>{{item.description}}</b-card-text>
                  <b-button href="#" variant="primary">Stiahnuť</b-button>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'

export default {
  name: 'Documents',
  data () {
    return {
      logo: logo,
      documents: [
        {
          id: 1,
          title: 'Charta práv pacienta',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Zdravotnictvo'
        },
        {
          id: 2,
          title: 'Informácie o právach',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Lieky'
        },
        {
          id: 3,
          title: 'Informácie o povinnostiach',
          description: 'Donec id elit non mi porta gravida at eget metus. Maecenas se',
          category: 'Právo'
        }
      ]
    }
  },
  mounted () {
    // xray.index()
  }
}
</script>

<style lang="scss">
.list-group-ext {
	.list-group-item {
		padding: 1.2rem 1.5rem;
		border-radius: 15px !important;
		border: 0;
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		box-shadow: 3px 5px 5px 1px rgba(0, 0, 0, 0.04);
		transition: all 0.3s ease-out;
		text-decoration: none;
		cursor: pointer;
		&:hover {
			background-image: linear-gradient(90deg, #7168b7 15%, #33c2bd 100%);
			box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.04);
			.list-group-item__text,
			.list-group-item__title {
				color: #ffffff;
			}
		}
	}
	.card {
		box-shadow: 3px 5px 5px 1px rgba(0, 0, 0, 0.04);
		transition: all 0.3s ease-out;
		&:hover {
			box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.04);
		}
	}

	.list-group-item__title {
		display: inline-flex;
		align-items: center;
		i {
			@include font-size(24px);
		}
	}

	.card-header {
		background-image: linear-gradient(
			to right,
			#0db5c8 0%,
			#089bab 100%
		) !important;
		color: #ffffff;
		border-radius: 15px 15px 0 0 !important;
	}

	.card {
		border-radius: 15px;
	}

	.card-title {
		margin: 0;
		margin-bottom: 1.5rem;
		color: $color-purple;
		font-weight: 700;
		line-height: 1.3;
		transition: font-size 0.3s cubic-bezier(0.16, 0.08, 0.355, 1);
		@include font-size(20px);
	}

	.list-group-item--box {
		padding: 2rem 1.5rem;
	}
	.btn-primary {
		padding: 0.5rem 1.3rem;
	}
}
</style>
