<template>
  <b-container fluid>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form id="add-employee" ref="addEmployee" novalidate @submit.prevent="handleSubmit(sendEmployerForm)">
        <b-row>
          <b-col lg="3">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Nový Zamestnávateľ</h4>
              </template>
              <template v-slot:body>
                <b-form-group class="txt-c txt-md-l">
                  <div class="add-img-user profile-img-edit">
                    <b-img class="profile-pic height-150 width-150" fluid :src="user.profile_image" alt="profile-pic" />
                  </div>
                </b-form-group>

                <b-form-group class="col-12" label="Meno:" label-for="firstname">
                  <ValidationProvider name="Meno" rules="required" v-slot="{ errors }">
                    <b-form-input v-model="user.firstname" type="text" disabled name="firstname" id="firstname" placeholder="Meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" label="Priezvisko:" label-for="lastname">
                  <ValidationProvider name="Priezvisko" rules="required" v-slot="{ errors }">
                    <b-form-input v-model="user.lastname" type="text" disabled name="lastname" id="lastname" placeholder="Priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
                <b-form-group class="col-12" label="Email:" label-for="email">
                  <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                    <b-form-input v-model="user.email" type="text" disabled name="email" id="email" placeholder="Email" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                    <div class="invalid-feedback">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </b-form-group>
              </template>
            </iq-card>
          </b-col>
          <b-col lg="9">
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">Obchodné údaje</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info">
                  <b-row>
                    <b-form-group class="col-12 col-sm-6 col-md-4" label="IČO:" label-for="ico">
                      <ValidationProvider name="IČO" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.company.ico" type="text" name="ico" id="ico" placeholder="IČO" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                    <b-form-group class="col-12 col-sm-6 col-md-4" label="Obchodné meno:" label-for="company_name">
                      <ValidationProvider name="Obchodné meno" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.company.name" type="text" name="company_name" id="company_name" placeholder="Obchodné meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>

                  <b-row>
                    <b-form-group class="col-12 col-sm-6 col-md-4" label="Ulica:" label-for="street">
                      <ValidationProvider name="Ulica" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.company.street" type="text" name="street" id="street" placeholder="Ulica" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-md-4" label="PSČ:" label-for="psc">
                      <ValidationProvider name="PSČ" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.company.psc" type="text" name="psc" id="psc" placeholder="PSČ" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-md-4" label="Mesto:" label-for="city">
                      <ValidationProvider name="Mesto" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.company.city" type="text" name="city" id="city" placeholder="Mesto" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>
                  </b-row>
                  <b-row class="margin-top-2">
                    <b-form-group class="col-12 col-sm-6 col-md-4" label="Kód lekára:" label-for="personalCode">
                      <ValidationProvider name="Kód lekára" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.personalCode" type="text" name="personalCode" id="personalCode" placeholder="Kód lekára" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-md-4" label="Kód PZS:" label-for="insuranceCode">
                      <ValidationProvider name="Kód PZS" rules="required" v-slot="{ errors }">
                        <b-form-input v-model="user.insuranceCode" type="text" name="insuranceCode" id="insuranceCode" placeholder="Kód PZS" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                  <b-form-group class="col-12 margin-top-1">
                    <b-form-checkbox v-model="user.employeesExist">Mám zamestnancov</b-form-checkbox>
                  </b-form-group>

                  </b-row>
                  <!-- <hr> -->
                </div>
              </template>
            </iq-card>
          </b-col>
        </b-row>
      </form>

      <b-row>
        <b-col lg="12">
          <template v-if="user.employeesExist">
            <iq-card class="box--blue">
              <template v-slot:headerTitle>
                <h4 class="card-title">Vyhľadaj zamestnanca</h4>
              </template>
              <template v-slot:body>
                <div class="new-user-info margin-top-1">
                  <b-row class="gutter-20">
                    <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3" >
                      <ValidationProvider name="Meno" v-slot="{ errors }">
                        <b-form-input v-model="filter.firstname" type="text" name="filter_firstname" id="filter_firstname" placeholder="Meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3" >
                      <ValidationProvider name="Priezvisko"  v-slot="{ errors }">
                        <b-form-input v-model="filter.lastname" type="text" name="filter_lastname" id="filter_lastname" placeholder="Priezvisko" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <b-form-group class="col-12 col-sm-6 col-md-4 col-lg-3">
                      <ValidationProvider name="Používateľské meno" v-slot="{ errors }">
                        <b-form-input v-model="filter.username" type="text" name="filter_username" id="filter_username" placeholder="Používateľské meno" :class="(errors.length > 0 ? ' is-invalid' : '')"></b-form-input>
                        <div class="invalid-feedback">
                          <span>{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>
                    </b-form-group>

                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                      <b-button variant="primary" class="btn-lg btn-responsive btn-lg-ext" @click="filterEmplyoees()"><i class="ri-search-2-line margin-right-1"></i> Vyhľadať</b-button>
                    </div>

                  </b-row>
                  <!-- <hr> -->
                </div>
              </template>
            </iq-card>

            <iq-card v-if="rowsEmployees">
              <template v-slot:headerTitle>
                <!-- <h4 class="card-title">Vyhľadaj zamestnanca</h4> -->
              </template>
              <template v-slot:body>
                <div class="new-user-info margin-top-1">
                  <!-- :select-options="{
                    enabled: true ,
                    selectOnCheckboxOnly: true,
                    selectionText: 'Vybratých zamestnancov',
                    clearSelectionText: 'zmazať',
                  }" -->
                  <vue-good-table
                    :columns="columnsEmployees"
                    :rows="rowsEmployees"
                    @on-page-change="onPageChange"
                    :pagination-options="{
                      enabled: true,
                      mode: 'records',
                      perPage: 5,
                      position: 'bottom',
                      perPageDropdown: [10, 20, 30],
                      dropdownAllowAll: false,
                      nextLabel: 'ďalšia',
                      prevLabel: 'predchadzajúca',
                      rowsPerPageLabel: 'Výsledkov',
                      ofLabel: 'z',
                      pageLabel: 'stránka', // for 'pages' mode
                      allLabel: 'Všetky',
                    }"
                    >

                    <template slot="table-row" slot-scope="props" :class="{ 'is-selected': props.row.selected }">
                      <span v-if="props.column.field == 'authorizations'">
                        <!-- <v-select name="user_id"  :options="typeOfAuthorizations"/> -->
                        <ValidationProvider name="oprávnenie" v-slot="{ errors }">
                          <span class="select-form">
                            <b-form-select v-model="props.row.authorizations" :id="'role_' + props.index" :name="'role_' + props.index" @change="selectedAuthorization($event, props.index)" placeholder="Vyberte oprávnenie" plain :options="typeOfAuthorizations" :class="(errors.length > 0 ? ' is-invalid' : '')">
                            </b-form-select>
                          </span>
                          <div class="invalid-feedback">
                            <span>{{ errors[0] }}</span>
                          </div>
                        </ValidationProvider>
                      </span>
                      <span v-else-if="props.column.field == 'dateFrom'">
                        <flat-pickr class="form-control" v-model="props.row.dateFrom" @input="selectedDateFrom($event, props.index)" :config="basicConfigDatepicker" placeholder="Oprávnenie od"></flat-pickr>
                      </span>
                      <span v-else-if="props.column.field == 'action'">
                        <div class="request__group">
                          <div class="request__btn request__btn--filled-accept" v-if="!props.row.selected" @click="toggleEmployee(props.row, props.index)"><i class="ri-add-line"></i></div>
                          <div class="request__btn request__btn--filled-decline" v-if="props.row.selected" @click="toggleEmployee(props.row, props.index)"><i class="ri-close-line"></i></div>
                        </div>
                      </span>
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>

                    <!-- <template slot="column-filter" slot-scope="{ column, updateFilters }">
                      <my-custom-filter
                        v-if="columns.filterOptions.customFilter"
                        @input="(value) => updateFilters(column, value)"/>
                    </template> -->

                  </vue-good-table>
                </div>
              </template>
            </iq-card>
          </template>
          <div class="text-right margin-top-2 margin-bottom-4">
            <b-button form="add-employee" variant="primary" class="btn-lg" type="submit">Uložiť údaje</b-button>
          </div>
        </b-col>
      </b-row>
    </ValidationObserver>
  </b-container>
</template>
<script>
import { xray } from '../../config/pluginInit'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import moment from 'moment'
// import router from '@/router'
// import axios from '@/services/index'

export default {
  name: 'AddEmployeer',
  components: {
    VueGoodTable
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      user: {
        degree: '',
        firstname: 'Jakub',
        lastname: 'Turský',
        username: 'JakubTu',
        email: 'jakubtursky769@gmail.com',
        profile_image: require('../../assets/images/user/08.jpg'),
        employer: true,
        personalCode: '',
        insuranceCode: '',
        employeesExist: false,
        employees: [],
        company: {
          name: '',
          ico: '',
          street: '',
          psc: '',
          city: ''
        }
      },
      filter: {
        firstname: '',
        lastname: '',
        username: ''
      },
      typeOfAuthorizations: ['Sklad', 'Dotaznik', 'Iné'],
      basicConfigDatepicker: {
        dateFormat: 'd-m-Y',
        minDate: 'today'
      },
      columnsEmployees: [
        { label: 'Meno', field: 'firstname' },
        { label: 'Priezvisko', field: 'lastname' },
        { label: 'Používatelské meno', field: 'username' },
        { label: 'Oprávnenia', field: 'authorizations', width: '200px' },
        { label: 'Dátum od', field: 'dateFrom', width: '200px' },
        { label: 'Akcia', field: 'action', width: '120px' }
      ],
      rowsEmployees: [
        { id: 0, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 1, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 2, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 3, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 4, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 5, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 6, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 7, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 8, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 9, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() },
        { id: 10, selected: false, firstname: 'Jana', lastname: 'Kováčová', username: 'JanaKov', authorizations: '', dateFrom: this.setToToday() }
      ]
    }
  },
  computed: {},
  methods: {
    setToToday: function () {
      let today = moment().format('DD-MM-YYYY')
      return today
    },
    selectedAuthorization: function (value, index) {
      this.rowsEmployees[index].authorizations = value
    },
    selectedDateFrom: function (value, index) {
      this.rowsEmployees[index].dateFrom = value
    },
    toggleEmployee: function (person, index) {
      this.rowsEmployees[index].selected = !this.rowsEmployees[index].selected
      let nthIndex = index + 1
      document.querySelector('#vgt-table tbody tr:nth-child(' + nthIndex + ')').classList.toggle('is-selected')

      // add row to user object with selected Employees
      this.user.employees = this.rowsEmployees.filter(item => item.selected === true)
    },
    onPageChange: function (params) {
      setTimeout(() => {
        window.jQuery('#vgt-table tbody tr .request__btn--filled-decline').each(function () {
          window.jQuery(this).closest('tr').addClass('is-selected')
        })
      }, 100)
    },
    filterEmplyoees () {
      if (
        this.filter.firstname.length > 0 ||
        this.filter.lastname.length > 0 ||
        this.filter.username.length > 0
      ) {
        console.log(this.filter)
      }
    },
    sendEmployerForm () {
      console.log(this.user)
      console.log(this.rowsEmployees)

      // axios.post('/contact', this.user)
      //   .then((res) => {
      //     console.log(res)
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   }).finally(() => {

      //   })
      return false
    }
  }
}
</script>

<style lang="scss">
.profile-img-edit {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.iq-bg-primary {
	cursor: pointer;
	&:hover {
		background: darken(#ceebee, 10%) !important;
	}
}

table.vgt-table {
	font-size: 14px;
}

.vgt-table thead th {
	padding: 1.2em 1.8em 1.2em 0.75em;
	vertical-align: middle;
	line-height: 1.4;
}

.vgt-wrap__footer .footer__row-count__label {
	font-size: 14px;
	margin-bottom: 0;
}

.vgt-wrap__footer .footer__navigation__page-btn span,
.vgt-wrap__footer .footer__navigation {
	font-size: 14px;
}

.vgt-wrap__footer .footer__row-count__select {
	font-size: 14px;
	border: 1px solid #d7dbda;
	border-radius: 10px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
	border-right-color: $color-green;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
	border-left-color: $color-green;
}

table.vgt-table td {
	vertical-align: middle;
}

table.vgt-table tr.is-selected {
	background: #eff7f8;
}

.vgt-table thead th:last-child {
	text-align: center;
}

.vgt-wrap__footer {
	border-top: 0;
	background: white;
}

.vgt-table th.vgt-checkbox-col {
	vertical-align: middle;
	input {
		width: 20px;
		height: 20px;
	}
}

.vgt-table th.vgt-checkbox-col,
.vgt-table th.line-numbers,
.vgt-table thead th {
	background: #ecf4ff;
	border: 1px solid #bcd1f1;
}

.vgt-table.bordered thead th {
	background: #ecf4ff;
	border: 1px solid #bcd1f1;
}

.request__group {
	display: flex;
	align-self: center;
	justify-content: center;
}

.request__btn {
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	// padding-left: 10px;
	// padding-right: 10px;
	width: 40px;
	height: 40px;
	cursor: pointer;
	font-weight: 500;
}

.request__btn--filled-accept {
	background: rgba(87, 173, 0, 0.1);
	color: #57ad00;
	&:hover {
		background: rgba(87, 173, 0, 0.3);
	}
	i {
		font-size: 24px;
		color: #57ad00;
	}
}

.request__btn--filled-decline {
	background: rgba(255, 65, 65, 0.2);
	color: #ff4141;
	&:hover {
		background: rgba(255, 65, 65, 0.4);
	}
	i {
		font-size: 24px;
		color: #ff4141;
	}
}
</style>
