import * as rules from 'vee-validate/dist/rules'
import { localize, extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import sk from 'vee-validate/dist/locale/sk.json'
import Vue from 'vue'

localize({
  sk
})
localize('sk')

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
