<template>
  <div class="main-wrapper">
    <Loader />
    <!-- TOP Nav Bar -->
    <NavBarStyle1 class="iq-top-navbar--full" title="Dashboard" :homeURL="{ name: '/' }" :logo="logo" :horizontal="horizontal" :items="horizontalMenu">
      <template slot="responsiveRight">
        <ul class="navbar-nav ml-auto navbar-list d-lg-none">
          <li class="nav-item">
            <router-link to="/casto-kladene-otazky">
              <a href="#">Často kladené otázky</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/dokumenty">
              <a href="#">Dokumenty</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/vyhladat-doktora">
              <a>
                <button type="button" class="btn btn-primary"><i class="ri-search-2-line margin-right-1"></i> Vyhladať doktora</button>
              </a>
            </router-link>
          </li>
        </ul>
      </template>
      <template slot="right">
        <ul class="navbar-list">
          <li class="nav-item d-none d-lg-inline-block">
            <router-link to="/casto-kladene-otazky">
              <a href="#">Často kladené otázky</a>
            </router-link>
          </li>
          <li class="nav-item d-none d-lg-inline-block">
            <router-link to="/dokumenty">
              <a href="#">Dokumenty</a>
            </router-link>
          </li>
          <li class="nav-item d-none d-lg-inline-block">
            <router-link to="/vyhladat-doktora">
              <a>
                <button type="button" class="btn btn-primary"><i class="ri-search-2-line margin-right-1"></i> Vyhladať doktora</button>
              </a>
            </router-link>
          </li>
          <li v-if="!logged" class="search-toggle-item">
            <router-link to="/auth/prihlasenie">
              <button type="button" class="btn btn-primary"><i class="ri-login-box-line margin-right-1"></i> Prihlásiť sa</button>
            </router-link>
          </li>
          <li v-if="logged" class="search-toggle-item">
            <a href="#" class="search-toggle user-profile iq-waves-effect d-flex align-items-center">
              <img :src="userProfile" class="img-fluid rounded mr-3" alt="user">
              <div class="caption">
                <h6 class="mb-0 line-height">Mudr. Jana Kováčová</h6>
                <span class="font-size-12">{{ $t('nav.user.available') }}</span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3">
                    <h5 class="mb-0 text-white line-height">Mudr. Jana Kováčová</h5>
                    <!-- <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span> -->
                  </div>
                  <a href="#" class="iq-sub-card iq-bg-primary-danger-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-danger">
                        <i class="ri-login-box-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Vstupiť do skladu</h6>
                        <!-- <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p> -->
                      </div>
                    </div>
                  </a>
                  <a href="#" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="ri-file-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">{{ $t('nav.user.profileTitle') }}</h6>
                        <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="iq-sub-card iq-bg-primary-success-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-success">
                        <i class="ri-profile-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">{{ $t('nav.user.profileEditTitle') }}</h6>
                        <p class="mb-0 font-size-12">{{ $t('nav.user.profileEditSub') }}</p>
                      </div>
                    </div>
                  </a>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">{{ $t('nav.user.signout') }}<i class="ri-logout-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </NavBarStyle1>

    <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                :leave-active-class="`animated ${animated.exit}`">
      <router-view/>
    </transition>

    <FooterStyle1>
      <template v-slot:left>
        <li class="list-inline-item"><router-link to="/pravidla-a-podmienky">Pravidla súkromia</router-link></li>
        <li class="list-inline-item"><router-link to="/pravidla-a-podmienky">Pravidlá používania</router-link></li>
      </template>
      <template v-slot:right>
        ©medici 2021 | vytvoril: <a href="https://jakubtursky.sk/" target="_blank">JT</a>
      </template>
    </FooterStyle1>
  </div>
</template>
<script>
import Loader from '../components/xray/loader/Loader'
import NavBarStyle1 from '../components/xray/navbars/NavBarStyle1'
import loader from '../assets/images/logo1.png'
import profile from '../assets/images/user/1.jpg'
import { xray } from '../config/pluginInit'
export default {
  name: 'IntroLayout',
  components: {
    Loader,
    NavBarStyle1
  },
  data () {
    return {
      logged: false,

      horizontal: false,
      mini: false,
      animated: { enter: 'zoomIn', exit: 'zoomOut' },
      logo: loader,
      userProfile: profile,
      animateClass: [
        { value: { enter: 'zoomIn', exit: 'zoomOut' }, text: 'Zoom' },
        { value: { enter: 'fadeInUp', exit: 'fadeOutDown' }, text: 'Fade' },
        { value: { enter: 'slideInLeft', exit: 'slideOutRight' }, text: 'Slide' },
        { value: { enter: 'rotateInDownLeft', exit: 'rotateOutDownLeft' }, text: 'Roll' }
      ],
      horizontalMenu: ['Položka 1', 'Položka 2']
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')
      this.$router.push({ name: 'auth1.sign-in1' })
    }
  },
  mounted () {
    xray.loaderInit()
    // xray.mainIndex()
  }
}
</script>
<style>
@import url("../assets/css/custom.css");
.modal-backdrop {
	opacity: 0.5;
}
</style>
