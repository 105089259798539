<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header">
            <div class="row margin-bottom-1">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <!-- <div class="header__subtitle">Benefity webovej vizitky</div> -->
                <h1 class="alfa header__title h--gradient">Pravidlá a podmienky</h1>

              </div>

            </div>
            <div class="row gutter-md-60 gutter-lg-60">
              <div class="col-12 col-md-7">
                <div class="margin-bottom-3 p--margin-1 h--margin-1-2">
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                  <p>Za mesačný poplatok si môžete od Expandeco prenajať lokálnu adresu kdekoľvek v EÚ. Lokálnu adresu si uvediete na svoj web, ako adresu na prijímanie reklamácií a vratiek. Po prijatí zásielky na zberné miesto balík zaevidujeme, spracujeme podľa individuálnych požiadaviek a skladníme. Následne, opäť podľa inštrukcií e-shopu balíky konsolidujeme a odosielame na adresu e-shopu podľa zvoleného časového intervalu.</p>
                </div>
                <router-link to="auth/registracia">
                  <button class="btn btn-lg btn-primary">Zaregistrovať sa</button>
                </router-link>

              </div>
              <div class="col-12 col-md-5 margin-bottom-3 d-none d-md-block">
                <div class="content-width-600 margin-center pos-r">
                  <img class="img--responsive" :src="require('../../assets/images/about/benefit-3.svg')" alt="" >
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'

export default {
  name: 'About',
  data () {
    return {
      logo: logo

    }
  },
  mounted () {
    // xray.index()
  }
}
</script>

<style lang="scss">
</style>
