<template>
  <div class="section intro">
    <div class="container">
      <div class="row">
        <div class="col-12 position-static">
          <div class="intro__header ">
            <div class="row">
              <div class="col-12 col-md-8">
                <!-- <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a> -->
                <div class="header__subtitle">Ponuka doktorov</div>
                <h1 class="alfa header__title h--gradient">Vyhladať doktora</h1>
                <!-- <div class="mb-5 header__content">
                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Excepturi, earum sunt! Excepturi nisi voluptatibus assumenda doloribus doloremque. Laboriosam quod iste ratione aperiam culpa, cupiditate neque deserunt inventore, exercitationem, veritatis laudantium!</p>
                </div> -->
                <!-- <button type="button" class="btn btn-primary btn-lg">Pozrieť si ponuku</button> -->
              </div>
              <div class="col-12 col-md-4 position-static d-none d-md-block">
                <transition name="slide-fade">
                  <img :src="require('../../assets/images/intro/search1.svg')"  alt="" class="img-fluid intro__img">
                </transition>
              </div>
            </div>
          </div>
          <div class="filter-group margin-bottom-6">
            <div class="row margin-bottom-1" v-if="queryResult.selectTypeValue == ''">
              <div class="col-12 col-md-6 col-lg-8 margin-bottom-2 margin-bottom-xs-0">
                <button class="btn btn-outline-primary btn-xxl btn-lg margin-right-xs-1 margin-right-sm-2 margin-bottom-1" @click="selectedType('county')"><i class="ri-road-map-line ri-lg icon-left"></i> Vyhladať podľa kraja</button>
                <button class="btn btn-outline-primary btn-xxl btn-lg margin-bottom-1" @click="selectedType('services')"><i class="ri-shield-cross-line ri-lg icon-left"></i> podľa služby</button>
              </div>
            </div>
            <div class="row margin-bottom-3" v-if="queryResult.selectTypeValue == 'county' && queryResult.selectedRegionNameValue == ''">
              <div class="col-12 col-md-6 col-lg-8 margin-bottom-2 margin-bottom-xs-0">
                <span class="a-link margin-bottom-1 a-link--hover-block" @click="resetTypeValue()">
                  <span class="a-link__icon"><i class="ri-arrow-left-s-line ri-lg"></i></span>
                  <span class="a-link__text a-link--hover-underline">Späť na výber typu hľadania</span>
                </span>
                <h2 class="epsilon">Vyberte kraj</h2>
                <div class="filter-group">
                  <div class="filter__item" v-for="region in regionsList" :key="region.id">
                    <button class="btn btn-outline-primary btn-xxl btn-xxl--ext btn-lg margin-bottom-1 margin-right-1" @click="selectedRegion(region.name, region.region_id)">
                      {{ region.name }}
                      <!-- <span class="filter__count">5</span> -->
                    </button>
                    <!-- <div class="districts-group">
                      <div class="regions__item" v-for="disctrict in region.disctricts" :key="disctrict.id">
                        <button class="btn btn-outline-primary btn-xxl btn-lg margin-bottom-1 margin-right-1">
                          {{ disctrict.name }}
                        </button>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-bottom-3" v-if="queryResult.selectTypeValue == 'county' && queryResult.selectedRegionNameValue != ''">
              <div class="col-12 col-md-6 col-lg-8 margin-bottom-2 margin-bottom-xs-0">
                <span class="a-link margin-bottom-1 a-link--hover-block" @click="resetDistrictValue()">
                  <span class="a-link__icon"><i class="ri-arrow-left-s-line ri-lg"></i></span>
                  <span class="a-link__text a-link--hover-underline">Späť na výber kraja</span>
                </span>
                <h2 class="epsilon">Vyberte okres</h2>
                <div class="filter-group">
                  <div class="filter__item" v-for="district in getSelectedDistrictsByRegion" :key="district.id">
                    <button class="btn btn-outline-primary btn-xxl btn-xxl--ext btn-lg margin-bottom-1 margin-right-1" @click="selectedDistrict(district.name, district.region_id)">
                      {{ district.name }}
                      <!-- <span class="filter__count">5</span> -->
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row margin-bottom-3" v-if="queryResult.selectTypeValue == 'services'">
              <div class="col-12 col-md-6 col-lg-8 margin-bottom-2 margin-bottom-xs-0">
                <span class="a-link margin-bottom-05 a-link--hover-block" @click="resetSpecializationValue()">
                  <span class="a-link__icon"><i class="ri-arrow-left-s-line ri-lg"></i></span>
                  <span class="a-link__text a-link--hover-underline">Späť na výber</span>
                </span>
                <h2 class="epsilon">Vyberte službu</h2>

                <div class="filter-group" :class="{'filter-group--cutted': !showAllSpecialization}">
                  <div class="filter__item" v-for="specialization in specializationList" :key="specialization.id">
                    <button class="btn btn-outline-primary btn-xxl btn-xxl--ext btn-lg margin-bottom-1 margin-right-1" @click="selectedSpecialization(specialization.name)">
                      {{ specialization.name }}
                      <!-- <span class="filter__count">5</span> -->
                    </button>
                    <button class="btn btn-primary btn-xxl btn-xxl--ext btn-lg margin-bottom-1" @click="showAllSpecialization = true" v-if="specialization.id === 6 && !showAllSpecialization"><i class="ri-add-line ri-lg margin-right-1"></i> Zobraziť všetky služby
                    </button>
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 p--margin-1">
                <p v-if="submitQueryErrors.length" >
                    <span class="text-danger" v-for="error in submitQueryErrors" :key="error">{{ error }}</span>
                </p>
              </div>
            </div>
            <div class="row margin-bottom-6">
              <div class="col-12 margin-bottom-2 margin-bottom-xs-0">
                <button class="btn btn-lg btn-primary btn-xxl" @click="submitQuery()">Vyhľadať <i class="ri-search-2-line ri-lg icon-right"></i></button>
              </div>
            </div>
          </div>
          <b-row class="iq-card-group" v-if="doctors.length">
            <b-col sm="12" md="6" lg="4" v-for="doctor in doctors" :key="doctor.message">
                <router-link :to="{ name: 'intro.profil-doktora', params: { doctorURL: doctor.url, doctorFullName: doctor.name}}">
                  <iq-card body-class="text-center">
                    <template v-slot:body>
                      <div class="doc-profile">
                        <img class="rounded-circle img-fluid avatar-80" :src="doctor.image" alt="profile">
                      </div>
                      <div class="iq-doc-info mt-3">
                        <h4> {{doctor.name}}</h4>
                        <p class="mb-0" >{{doctor.specialist}}</p>
                        <!-- <a href="javascript:void(0);">{{doctor.website}}</a> -->
                      </div>
                      <div class="iq-doc-description mt-2">
                        <p class="mb-0">{{doctor.description}}</p>
                      </div>
                      <!-- <div class="iq-doc-social-info mt-3 mb-3">
                        <ul class="m-0 p-0 list-inline">
                          <li><a href="#"><i class="ri-facebook-fill"></i></a></li>
                          <li><a href="#"><i class="ri-twitter-fill"></i></a> </li>
                          <li><a href="#"><i class="ri-google-fill"></i></a></li>
                        </ul>
                      </div> -->
                      <a href="#" class="btn btn-primary btn-lg mt-3">Pozrieť si profil</a>
                    </template>
                  </iq-card>
                </router-link>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { xray } from '../../config/pluginInit'
import logo from '../../assets/images/logo1.png'
import regions from '@/data/regions.json'
import districts from '@/data/districts.json'
import specialization from '@/data/specialization.json'

export default {
  name: 'Doctors',
  data () {
    return {
      submitQueryErrors: [],
      showAllSpecialization: false,
      logo: logo,
      regionsList: regions,
      districtsList: districts,
      specializationList: specialization,
      queryResult: {
        selectTypeValue: '',
        selectedRegionNameValue: '',
        selectedRegionIDValue: '',
        selectedDistrictValue: '',
        selectedDistrictIDValue: '',
        selectSpecializationValue: ''
      },
      doctors: [
        { id: 0, url: 'mudr-jana-kovacova', name: 'Mudr. Jana Kováčová', specialist: 'Kardiologička', image: require('../../assets/images/user/1.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 1, url: 'bunny-joy', name: 'Dr. Bunny Joy', specialist: 'Gynaecology', image: require('../../assets/images/user/01.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 2, url: 'mudr-jana-kovacova', name: 'Dr. Shonda Leer', specialist: 'Orthopedics Special', image: require('../../assets/images/user/02.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 3, url: 'mudr-jana-kovacova', name: 'Dr. Ira Membrit', specialist: 'MD', image: require('../../assets/images/user/03.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 4, url: 'mudr-jana-kovacova', name: 'Dr. Paul Molive', specialist: 'Medicine Specialists', image: require('../../assets/images/user/04.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 5, url: 'mudr-jana-kovacova', name: 'Dr. Dr. Terry Aki', specialist: 'Heart Surgeons', image: require('../../assets/images/user/05.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 6, url: 'mudr-jana-kovacova', name: 'Dr. Poppa Cherry', specialist: 'Family Physicians', image: require('../../assets/images/user/06.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 7, url: 'mudr-jana-kovacova', name: 'Dr. Dr. Saul T. Balls', specialist: 'Gynaecology', image: require('../../assets/images/user/07.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 8, url: 'mudr-jana-kovacova', name: 'Dr. Hal Appeno', specialist: 'MD', image: require('../../assets/images/user/08.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 9, url: 'mudr-jana-kovacova', name: 'Dr. Polly Tech', specialist: 'Eye Special', image: require('../../assets/images/user/09.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 10, url: 'mudr-jana-kovacova', name: 'Dr. Pat Agonia', specialist: 'Therapy Special', image: require('../../assets/images/user/10.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 11, url: 'mudr-jana-kovacova', name: 'Dr. Barry Cade', specialist: 'Heart Surgeons', image: require('../../assets/images/user/12.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 12, url: 'mudr-jana-kovacova', name: 'Dr.Jimmy Changa', specialist: 'Cardiologists', image: require('../../assets/images/user/12.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 13, url: 'mudr-jana-kovacova', name: 'Dr. Sue Vaneer', specialist: 'Orthopedics Special', image: require('../../assets/images/user/13.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 14, url: 'mudr-jana-kovacova', name: 'Dr. Monty Carlo', specialist: 'Anesthesiologists', image: require('../../assets/images/user/14.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' },
        { id: 15, url: 'mudr-jana-kovacova', name: 'Dr. Rick O\'Shea', specialist: 'General', image: require('../../assets/images/user/15.jpg'), description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam auctor non erat non gravida. In id ipsum consequat' }
      ]
    }
  },
  mounted () {
    // xray.index()
  },
  methods: {
    selectedType (type) {
      this.submitQueryErrors = []
      this.queryResult.selectTypeValue = type
    },
    selectedSpecialization (value) {
      this.queryResult.selectSpecializationValue = value
    },
    resetSpecializationValue () {
      this.queryResult.selectTypeValue = ''
      this.queryResult.selectSpecializationValue = ''
    },
    resetTypeValue () {
      this.queryResult.selectTypeValue = ''
    },
    selectedRegion (regionName, regionID) {
      this.queryResult.selectedRegionNameValue = regionName
      this.queryResult.selectedRegionIDValue = regionID
    },
    resetRegionValue () {
      this.queryResult.selectedRegionNameValue = ''
      this.queryResult.selectedRegionIDValue = ''
    },
    selectedDistrict (district, districtID) {
      this.queryResult.selectedDistrictValue = district
      this.queryResult.selectedDistrictIDValue = districtID
    },
    resetDistrictValue () {
      this.queryResult.selectedRegionNameValue = ''
      this.queryResult.selectedRegionIDValue = ''
      this.queryResult.selectedDistrictValue = ''
      this.queryResult.selectedDistrictIDValue = ''
    },
    checkFormValidity () {
      this.submitQueryErrors = []

      if (this.queryResult.selectTypeValue.length === 0) {
        this.submitQueryErrors.push('Vyberte spôsob hľadania doktora')
      };

      if (!this.submitQueryErrors.length) {
        return true
      } else {
        return false
      }
    },
    submitQuery () {
      if (!this.checkFormValidity()) {
        return
      }

      console.log('QueryToDB...')
    }
  },
  computed: {
    getSelectedDistrictsByRegion () {
      let query = this.queryResult.selectedRegionIDValue
      let selectedDistrict = this.districtsList.filter(x => x.region_id === query)
      return selectedDistrict
    }
  }
}
</script>

<style lang="scss">
.btn.btn-xxl {
	display: inline-flex;
	align-items: center;
	padding: 1rem 1.6rem;
	@include font-size(16px);
	&.btn-xxl--ext {
		padding: 0.4rem 0.4rem 0.4rem 1.2rem;
		@include font-size(14px);
		@include bp(small) {
			padding: 0.6rem 1rem 0.6rem 1.6rem;
			@include font-size(16px);
		}
	}
	i {
		&.icon-left {
			position: relative;
			top: -1px;
			margin-left: 0;
			margin-right: 0.8rem;
		}
		&.icon-right {
			position: relative;
			top: -1px;
			margin-right: 0;
			// margin-right: 0.8rem;
			margin-left: 0.8rem;
		}
	}
}

.iq-card-group {
	.iq-card {
		border-radius: 15px;
		box-shadow: 3px 5px 5px 1px rgba(0, 0, 0, 0.04);
		transition: all 0.3s ease-out;
		cursor: pointer;
		transform: scale(1) translateY(0);
		&:hover {
			transform: scale(1.02) translateY(-10px);
			box-shadow: 6px 6px 15px 5px rgba(0, 0, 0, 0.04);
		}
	}
	.iq-card-body {
		padding: 2rem 1.5rem;
	}
}

.filter-group--cutted {
	.filter__item {
		&:nth-child(1n + 8) {
			display: none;
		}
	}
}

.filter__item {
	display: inline-block;
	margin-right: -4px;
	.btn {
		&:hover {
			.filter__count {
				border-color: white;
				color: white;
			}
		}
	}
}

.filter__count {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	@include font-size(12px);
	font-weight: 700;
	border-radius: 100%;
	margin-left: 1rem;
	color: white;
	// color: $color-green;
	background-color: $color-green;
	// border: 1px solid $color-green;
}
</style>
