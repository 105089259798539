<template>
  <div>
    <div class="h--gradient h--margin-05 margin-top-1">
      <h1 class="beta">Registrovať sa</h1>
    </div>
    <div class="p--margin-06 p--size-16">
      <p>Zadaj tvoju emailovu addresu a heslo pre registráciu</p>
    </div>
    <sign-up-form formType="jwt"></sign-up-form>
  </div>
</template>
<script>

import SignUpForm from './Forms/SignUpForm'
import auth0 from 'auth0-js'
import constant from '../../../config/constant'

export default {
  name: 'SignUp',
  components: { SignUpForm },
  data: () => ({}),
  methods: {
    loginOAuth0 () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  }
}
</script>
