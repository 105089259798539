<template>
  <div>
    <div class="h--gradient h--margin-05 margin-top-1">
      <h1 class="beta">Prihlásiť sa</h1>
    </div>
    <div class="p--margin-06 p--size-16">
      <p>Zadaj tvoju emailovu addresu a heslo pre pristup do skladu</p>
    </div>
    <sign-in-form formType="jwt" email="admin@xray.com" password="admin123"></sign-in-form>
  </div>
</template>

<script>
import auth0 from 'auth0-js'
import SignInForm from './Forms/SignInForm'
import constant from '../../../config/constant'

export default {
  name: 'SignIn',
  components: { SignInForm },
  data: () => ({}),
  mounted () {
    const loggedIn = localStorage.getItem('access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'dashboard' })
    }
  },
  methods: {
    loginOAuth0: function () {
      new auth0.WebAuth(constant.auth0Config).authorize()
    }
  }
}
</script>
