<template>
  <div>
    <Loader />

    <!-- TOP Nav Bar -->
    <NavBarStyle1 class="iq-top-navbar--full" title="Dashboard" :homeURL="{ name: 'intro' }" :logo="logoHeader" :horizontal="horizontal" :items="horizontalMenu">
      <template slot="responsiveRight">
        <ul class="navbar-nav ml-auto navbar-list d-lg-none">
          <li class="nav-item">
            <router-link to="/dokumenty">
              <a href="#">Dokumenty</a>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/vyhladat-doktora">
              <a>
                <button type="button" class="btn btn-primary"><i class="ri-search-2-line margin-right-1"></i> Vyhladať doktora</button>
              </a>
            </router-link>
          </li>
        </ul>
      </template>
      <template slot="right">
        <ul class="navbar-list">
          <li class="nav-item d-none d-lg-inline-block">
            <router-link to="/dokumenty">
              <a href="#">Dokumenty</a>
            </router-link>
          </li>
          <li class="nav-item d-none d-lg-inline-block">
            <router-link to="/vyhladat-doktora">
              <a>
                <button type="button" class="btn btn-primary"><i class="ri-search-2-line margin-right-1"></i> Vyhladať doktora</button>
              </a>
            </router-link>
          </li>
          <li v-if="!logged" class="search-toggle-item">
            <router-link to="/auth/prihlasenie">
              <button type="button" class="btn btn-primary"><i class="ri-login-box-line margin-right-1"></i> Prihlásiť sa</button>
            </router-link>
          </li>
          <li v-if="logged" class="search-toggle-item">
            <a href="#" class="search-toggle user-profile iq-waves-effect d-flex align-items-center">
              <img :src="userProfile" class="img-fluid rounded mr-3" alt="user">
              <div class="caption">
                <h6 class="mb-0 line-height">Mudr. Jana Kováčová</h6>
                <span class="font-size-12">{{ $t('nav.user.available') }}</span>
              </div>
            </a>
            <div class="iq-sub-dropdown iq-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 ">
                  <div class="bg-primary p-3">
                    <h5 class="mb-0 text-white line-height">Mudr. Jana Kováčová</h5>
                    <!-- <span class="text-white font-size-12">{{ $t('nav.user.available') }}</span> -->
                  </div>
                  <a href="#" class="iq-sub-card iq-bg-primary-danger-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-danger">
                        <i class="ri-login-box-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">Vstupiť do skladu</h6>
                        <!-- <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p> -->
                      </div>
                    </div>
                  </a>
                  <a href="#" class="iq-sub-card iq-bg-primary-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-primary">
                        <i class="ri-file-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">{{ $t('nav.user.profileTitle') }}</h6>
                        <p class="mb-0 font-size-12">{{ $t('nav.user.profileSub') }}</p>
                      </div>
                    </div>
                  </a>
                  <a href="#" class="iq-sub-card iq-bg-primary-success-hover">
                    <div class="media align-items-center">
                      <div class="rounded iq-card-icon iq-bg-success">
                        <i class="ri-profile-line"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="mb-0 ">{{ $t('nav.user.profileEditTitle') }}</h6>
                        <p class="mb-0 font-size-12">{{ $t('nav.user.profileEditSub') }}</p>
                      </div>
                    </div>
                  </a>
                  <div class="d-inline-block w-100 text-center p-3">
                    <a class="iq-bg-danger iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">{{ $t('nav.user.signout') }}<i class="ri-logout-box-line ml-2"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </NavBarStyle1>

    <section class="sign-in-page">
      <b-container class="sign-in-page-bg margin-top-7 margin-top-md-9 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white p--white">
              <a class="sign-in-logo mb-5" href="#"><img :src="logo" class="img-fluid" alt="logo"></a>
              <Slick :option="slickOptions">
                <div class="item">
                  <img src="../../assets/images/login/1.png" class="img-fluid mb-4" alt="logo">
                  <h2 class="delta mb-3 text-white">Benefit číslo 1.</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                  <img src="@/assets/images/login/2.png" class="img-fluid mb-4" alt="logo">
                  <h2 class="delta mb-3 text-white">Benefit číslo 2.</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
                <div class="item">
                  <img src="../../assets/images/login/3.png" class="img-fluid mb-4" alt="logo">
                  <h2 class="delta mb-3 text-white">Benefit číslo 3.</h2>
                  <p>It is a long established fact that a reader will be distracted by the readable content.</p>
                </div>
              </Slick>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from '../../components/xray/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import { xray } from '../../config/pluginInit'
import NavBarStyle1 from '@/components/xray/navbars/NavBarStyle1'
import logoHeader from '@/assets/images/logo1.png'
import profile from '@/assets/images/user/1.jpg'
import bgImage from '../../assets/images/login/2.png'
export default {
  name: 'AuthLayout',
  components: {
    Loader,
    NavBarStyle1
  },
  mounted () {
    xray.index()
  },
  data () {
    return {
      logged: false,
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        gutter: 30,
        dots: true
      },
      logo: logo,
      logoHeader: logoHeader,
      bgImageURL: bgImage,
      userProfile: profile,
      horizontal: false,
      horizontalMenu: ['Položka 1', 'Položka 2']
    }
  }
}
</script>

<style lang="scss">
.slick-track {
	margin: 0 -15px;
}
.slick-track > .slick-slide {
	padding: 0 15px;
}

.slick-dots li button {
	width: 30px;
	height: 30px;
	&:before {
		@include font-size(14px);
		color: white;
		transition: opacity 250ms ease-out;
	}
}
.slick-dots li.slick-active button:before {
	color: white;
}
</style>
